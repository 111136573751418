import maybeJs from 'maybe-js';

export const existsOrElse = (obj, path, fallback) => {
  const elements = path.split('.');
  const wrapped  = elements
    .reduce(
      (box, property) => maybeJs(box.value).isValue() ? maybeJs(box.value[property]) : maybeJs.nothing,
      maybeJs(obj)
    );

  return wrapped.or(fallback).value;
};

export const maybe = maybeJs;
