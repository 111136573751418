<template>
  <svgicon icon="loading" :fill="false" class="spin h-auto"
           width="25" color="#858585"/>
</template>

<script>
import '@/svg-icons/loading';
export default {
  name: 'LoadingSpinner',
};
</script>
