import DOMPurify from 'dompurify';
const allowedTags = ['span', 'br', 'a', 'b', 'small'];
const allowedAttributes = ['class', 'style', 'id', 'href', 'target'];

export function sanitizeHTML (html, allowedTagsLocally = [], allowedAttributesLocally = []) {
  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: getAllowedTags(allowedTagsLocally),
    ALLOWED_ATTR: getAllowedAttributes(allowedAttributesLocally),
  });
}

function getAllowedTags (allowedTagsLocally) {
  return getUniqueValues([...allowedTags, ...allowedTagsLocally]);
}

function getAllowedAttributes (allowedAttributesLocally) {
  return getUniqueValues([...allowedAttributes, ...allowedAttributesLocally]);
}

function getUniqueValues (array) {
  return [...new Set(array)];
}
