<template>
  <div class="brand-cover bg-light d-flex flex-column justify-content-center">
    <div class="container-fluid p-4 text-center">
      <svgicon class="brand-cover__logo my-4" color="#858585 #858585 #858585 #858585 #858585 r-#858585 #858585 #858585" icon="logo"
               v-if="!isWhiteLabel && !hideLogo"/>
      <div>
        <slot>
          <loading-spinner/>
        </slot>
        <slot name="extra"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import '@/svg-icons/logo';
  import LoadingSpinner from '@/components/system-feedback/LoadingSpinner';
  import { mapState } from 'vuex';

  export default {
    name:  'BrandCover',
    props: {
      hideLogo: {
        type:    Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState('appConfig', ['isWhiteLabel']),
    },
    components: { LoadingSpinner },
  };
</script>

<style lang="scss" scoped>
  .brand-cover {
    min-height: 100%;

    &__logo {
      width: 150px !important;
      height: auto !important;
      @include media-breakpoint-down(sm) {
        width: 130px !important;
      }
    }
  }
</style>
