/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fitbiz/icon-pie-chart': {
    width: 68.922,
    height: 68.922,
    viewBox: '0 0 68.922 68.922',
    data: '<path pid="0" d="M37.828 11.261a.907.907 0 00-.907.907v19.955a.907.907 0 00.907.907h19.955a.907.907 0 00.907-.907 20.886 20.886 0 00-20.862-20.862z" _fill="#fee100"/><path pid="1" d="M50.144 55.865L34.161 35.318v-25.1a1.159 1.159 0 00-1.159-1.159C19.58 9.057 8.662 21.016 8.662 35.714A26.688 26.688 0 0035.32 62.372a19.8 19.8 0 0014.643-4.9 1.159 1.159 0 00.181-1.607z" _fill="#fe5712"/><path pid="2" d="M57.783 34.844H39.642a.907.907 0 00-.706 1.477l11.792 14.6a.907.907 0 00.642.336h.063a.908.908 0 00.619-.243 20.942 20.942 0 006.638-15.26.907.907 0 00-.907-.91z" _fill="#02caf8"/><g _fill="none"><path pid="3" d="M34.461 0A34.461 34.461 0 110 34.461 34.461 34.461 0 0134.461 0z"/><path pid="4" d="M34.46 2a32.355 32.355 0 00-22.953 9.508A32.355 32.355 0 002 34.46a32.355 32.355 0 009.508 22.953 32.355 32.355 0 0022.953 9.508 32.355 32.355 0 0022.953-9.508 32.355 32.355 0 009.508-22.953 32.355 32.355 0 00-9.508-22.953A32.355 32.355 0 0034.461 2m0-2c19.032 0 34.46 15.429 34.46 34.46 0 19.033-15.428 34.462-34.46 34.462S0 53.492 0 34.46C0 15.429 15.429 0 34.46 0z" _fill="#5b5a61"/></g>'
  }
})
