/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fitbiz/arrow-icon': {
    width: 185.014,
    height: 92.189,
    viewBox: '0 0 185.014 92.189',
    data: '<path pid="0" d="M155.793 90.468c-1.238-1.848-.629-4.042 1.553-5.534 1.278-.874 2.572-1.728 3.859-2.59-.033-.1-.068-.211-.1-.317-.85.174-1.717.3-2.551.529-17.812 4.955-36.062 5.8-54.536 4.958q-2.219-.1-4.406-.293c-19.364-1.695-36.926-8.668-52.873-20.437-8.012-5.917-14.879-13.121-21.757-20.309A188.024 188.024 0 010 14.456V0a160.406 160.406 0 0011.894 18.939 205.628 205.628 0 0035.838 38.978A91.436 91.436 0 0095.6 79.079a186.45 186.45 0 0027.247 1.034 151.889 151.889 0 0037.411-5.913c.71-.2 1.718.129 2.159-.95-2.82-.968-5.647-1.816-8.379-2.925a3.811 3.811 0 01-2.395-4.825 3.685 3.685 0 014.475-2.356q11 2.148 21.973 4.4a17.391 17.391 0 013.38 1.061c3.861 1.64 4.693 5.3 1.869 8.326a19.281 19.281 0 01-3.113 2.613c-1.991 1.387-4.041 2.687-6.093 3.981-4.187 2.64-8.38 5.273-12.6 7.866a4.972 4.972 0 01-2.589.791 3.716 3.716 0 01-3.152-1.714z" _fill="#fff"/>'
  }
})
