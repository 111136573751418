<template>
  <div class="radio-group-card">
    <ValidationProvider ref="ValidationProvider" :name="schema.label" :vid="name"
                        :rules="rules"
                        #default="{ valid, invalid, errors }">
      <div class="radio-group-card__list">
        <b-form-radio-group v-model="model"
                            :class="{
                              'row': isHorizontal
                            }"
                            :name="name"
                            :state="errors[0] ? false : null">
          <div :class="{'col-12': isHorizontal && !innerOptionClass, [innerOptionClass]: true}"
               :key="option[optionsTrackBy]"
               v-for="option in schema.options">
            <b-card :class="{'selected': model===option[optionsTrackBy], 'disabled': option[optionsDisable]}"
                    @click="$refs[option[optionsTrackBy]][0].$el.childNodes[0].click()"
                    class="radio-group-card__option mb-2"
                    :id="getCardId(option)">
              <b-form-radio :disabled="schema.disabled || option[optionsDisable]" :ref="option[optionsTrackBy]" :value="option[optionsTrackBy]">
                <slot :normal-label="option[optionsLabel]" :option="option">
                  {{ option[optionsLabel] }}
                </slot>
              </b-form-radio>
            </b-card>
            <slot name="after-option" :option="option">
            </slot>
          </div>
        </b-form-radio-group>
      </div>
      <b-form-invalid-feedback :force-show="invalid">{{ errors[0] }}</b-form-invalid-feedback>
    </ValidationProvider>
  </div>
</template>

<script>
  import { BCard, BFormRadioGroup, BFormInvalidFeedback, BFormRadio } from 'bootstrap-vue';
  import { FormComponentMixins } from 'vue-form-components';

  export default {
    name:   'RadioGroupCard',
    mixins: [FormComponentMixins],
    props:  {
      value: {
        required: true,
      },
      innerOptionClass: {
        type:    String,
        default: '',
      },
      isHorizontal: {
        type:    Boolean,
        default: false,
      },
    },
    computed: {
      optionsTrackBy () {
        return this.schema.options_track_by || 'value';
      },
      storeObject () {
        return this.schema.is_options_store_object;
      },
      optionsLabel () {
        return this.schema.options_label || 'text';
      },
      optionsDisable () {
        return this.schema.options_disable || 'disabled';
      },
      model: {
        get () {
          return this.storeObject ? (this.value ? this.value[this.optionsTrackBy] : null) : this.value;
        },
        set (newValue) {
          if (this.storeObject) {
            newValue = this.schema.options.find((object) => object[this.optionsTrackBy] === newValue);
          }
          this.$emit('input', newValue, this.schema);
        },
      },
    },
    methods: {
      getCardId (value) {
        if (value.provider) {
          return `radio-group-card-${this.schema.property}-provider-${value.provider.id}-${value.provider.name}-account-${value.id}`;
        }

        return false;
      },
    },
    components: {
      BCard,
      BFormRadioGroup,
      BFormRadio,
      BFormInvalidFeedback,
    },
  };
</script>
<style lang="scss">
  .radio-group-card__option.disabled {
    cursor: not-allowed;
    background-color: darken($input-disabled-bg, 5);
    & * {
      cursor: not-allowed !important;
    }
  }
  .radio-group-card {
    &__option, input, label {
      border-width: 1.5px;
      cursor: pointer;
    }
    &__option.selected {
      border-color: $primary;
      color: $primary !important;
      background: lighten($primary, 47%);

      label {
        font-weight: 700;
      }
    }
    .custom-control-label {
      &::before, &::after {
        top: calc(50% - 7px);
      }
      display: flex;
      align-items: center;
    }
  }
</style>
