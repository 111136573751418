<template>
  <div id="app">
    <transition name="fade">
      <brand-cover v-if="$route.name===null"/>
      <router-view v-else/>
    </transition>
    <feedback-modal/>
    <choice-modal/>
    <fitbiz-modal/>
    <toast-notification/>
    <progress-bar/>
  </div>
</template>

<script>
import BrandCover from '@/components/general/layout/BrandCover';
import FeedbackModal from '@/components/system-feedback/FeedbackModal';
import ChoiceModal from '@/components/system-feedback/ChoiceModal';
import FitbizModal from '@/components/system-feedback/FitbizModal';
import ToastNotification from '@/components/system-feedback/ToastNotification';
import ProgressBar from '@/components/system-feedback/ProgressBar';

export default {
  name: 'App',

  socket: {
    events: {
      'user.refresh': async function () {
        await this.$store.dispatch('auth/refreshToken', { isForce: true });
      },
    },
  },

  components: {
    BrandCover,
    FeedbackModal,
    ChoiceModal,
    FitbizModal,
    ToastNotification,
    ProgressBar,
  },
};
</script>

<style lang="scss" scoped>
.fade {
  &-leave-active {
    z-index: 1500;
    position: fixed;
    width: 100%;
  }
}
</style>
