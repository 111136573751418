import auth from '@/api/auth';
import { existsOrElse } from '@/helpers/maybe';
import routerBuild from '@/router';
import { guessTimezone } from '@/helpers/utils';
import { generateGravatarUrlForEmail } from '@/helpers/gravatar';

// initial state
const state = {
  token:                null,
  expiry:               null,
  twoFactorCode:        null,
  userDetails:          null,
  refreshTokenPromise:  null,
  showCountrySelection: true,
};

// getters
const getters = {
  isLoggedIn:   (state, getters) => !!getters.token(),
  token:        state => () => {
    if (new Date().getTime() > state.expiry) {
      return null;
    }

    return state.token;
  },
  twoFactorCode:            state => existsOrElse(state, 'twoFactorCode', null),
  companyId:                state => existsOrElse(state, 'userDetails.company_id', undefined),
  userId:                   state => existsOrElse(state, 'userDetails.user_id', undefined),
  userTimezone:             state => existsOrElse(state, 'userDetails.time_zone', guessTimezone()),
  userPlanAccessLevel:      state => existsOrElse(state, 'userDetails.plan.access_level', undefined),
  userSocketPrivateRoomId:  state => existsOrElse(state, 'userDetails.socket_private_room_id', undefined),
  userDetails:              state => existsOrElse(state, 'userDetails', undefined),
  userCountryCode:          state => existsOrElse(state, 'userDetails.country_code', undefined),
  stripeTrialDaysLeft:      state => existsOrElse(state, 'userDetails.trial_days_left', 0),
  hasUserUsedTrial:         state => (existsOrElse(state, 'userDetails.trial_days_left', false) !== false),
  isTrialActive:           (state, getters) => !!(getters.hasUserUsedTrial && getters.stripeTrialDaysLeft > 0),
  isStripeTrialExpired:     (state, getters) => !!(getters.hasUserUsedTrial && getters.stripeTrialDaysLeft === 0),
  billingCycle:             state => existsOrElse(state, 'userDetails.billing_cycle', undefined),
  suggestedUpdatePlanId:    state => existsOrElse(state, 'userDetails.plan.suggested_update_plan_id', undefined),
  showFeatureLabelFlag:     state => existsOrElse(state, 'userDetails.plan.show_feature_label', false),
  showFinishStepLabelFlag:  state => existsOrElse(state, 'userDetails.plan.show_finish_step_label', false),
  showDynamicLabelFlag:     state => existsOrElse(state, 'userDetails.plan.show_dynamic_label', false),
  showSchedulerLabelFlag:   state => existsOrElse(state, 'userDetails.plan.show_scheduler_label', false),
  planLocationLimit:        state => existsOrElse(state, 'userDetails.plan.location_limit', false),
  newSubscriptionDiscount:  state => existsOrElse(state, 'userDetails.new_subscription_discount', false),
  isOldPremiumUser:         state => existsOrElse(state, 'userDetails.is_old_premium_user', false),
  isBillingPlanCancelled:   state => existsOrElse(state, 'userDetails.is_billing_plan_cancelled', false),
};

// actions
const actions = {
  async verifyAndLogin ({ commit }, { verificationCode, userId }) {
    try {
      const response = await auth.verify(verificationCode, userId);
      commit('setUserDetails', existsOrElse(response, 'data.data', null));
      commit('setUserGravatarUrl', generateGravatarUrlForEmail(response.data.data.email));
      commit('setTwoFactorCode', existsOrElse(response, 'data.meta.twoFactorCode', null));
      commit('setToken', existsOrElse(response, 'data.meta.token', null));
      // to skip the refresh token to be called straight after login
      commit('setRefreshTokenPromise', Promise.resolve());

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async login ({ commit }, { email, password }) {
    try {
      const response = await auth.login(email, password);
      commit('setUserDetails', existsOrElse(response, 'data.data', null));
      commit('setUserGravatarUrl', generateGravatarUrlForEmail(email));
      commit('setToken', existsOrElse(response, 'data.meta.token', null));
      // to skip the refresh token to be called straight after login
      commit('setRefreshTokenPromise', Promise.resolve());

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // call this action when previous request receive 401 status or use this to check if the user is still valid
  refreshToken ({ commit, state, dispatch }, { isForce = false, showExpireToast = false } = {}) {
    if (state.refreshTokenPromise && !isForce) {
      return state.refreshTokenPromise;
    }
    const promise = new Promise(async (resolve, reject) => {
      try {
        const response = await auth.refreshToken();
        commit('setUserDetails', existsOrElse(response, 'data.data', null));
        commit('setUserGravatarUrl', generateGravatarUrlForEmail(response.data.data.email));
        commit('setToken', existsOrElse(response, 'data.meta.token', null));
        commit('socketPrivateRoomId', existsOrElse(response, 'data.socket_private_room_id', null));
        resolve(response);
      } catch (error) {
        commit('resetAuth');
        dispatch('logoutRedirect', { isLoginRedirectCurrentPath: true, redirectPath: 'Login' });
        if (showExpireToast) {
          dispatch('systemFeedback/showToast', {
            content: 'Your session has expired. Please login again.',
            variant: 'error',
          }, { root: true });
        }
        reject(error);
      }
    });
    commit('setRefreshTokenPromise', promise);

    return promise;
  },
  async logout ({ commit, dispatch }) {
    try {
      const response = await auth.logout();
      commit('resetAuth');
      commit('systemFeedback/resetSupportInfobox', null, { root: true });

      // reverse the skip redirect mechanism after logout
      commit('appConfig/setSkipRedirectionCreateIntegration', false, { root: true });
      dispatch('logoutRedirect');

      return response;
    } catch (error) {
      commit('resetAuth');
      commit('systemFeedback/resetSupportInfobox', null, { root: true });
      // reverse the skip redirect mechanism after logout
      commit('appConfig/setSkipRedirectionCreateIntegration', false, { root: true });
      dispatch('logoutRedirect');

      return Promise.reject(error);
    }
  },
  logoutRedirect ({ rootState }, { isLoginRedirectCurrentPath = false, redirectPath } = {}) {
    const redirectPathName = redirectPath || (rootState.appConfig.isWhiteLabel ? 'Logout' : 'Login');
    const currentRoute = routerBuild().currentRoute;
    if (currentRoute.name === redirectPathName) {
      return false;
    }
    // to redirect the user to the original page after successful login.
    routerBuild().push({
      name:  redirectPathName,
      query: { successNext: isLoginRedirectCurrentPath ? encodeURIComponent(currentRoute.fullPath) : undefined },
    });
  },
  async setUserCountryCode ({ commit }, { countryCode }) {
    return commit('setUserCountryCode', countryCode);
  },
};

// mutations
const mutations = {
  setToken (state, token) {
    state.token = token;
    state.expiry = new Date().getTime() + 60 * 1000 * process.env.VUE_APP_TOKEN_EXPIRE_TIME;
  },
  socketPrivateRoomId (state, socketPrivateRoomId) {
    state.socketPrivateRoomId = socketPrivateRoomId;
  },
  setTwoFactorCode (state, code) {
    state.twoFactorCode = code;
  },
  setUserDetails (state, userDetails) {
    state.userDetails = userDetails;
  },
  setUserGravatarUrl (state, gravatarUrl) {
    state.userDetails.gravatar_url = gravatarUrl;
  },
  setUserCountryCode (state, countryCode) {
    state.userDetails.country_code = countryCode;
  },
  setRefreshTokenPromise (state, promise) {
    state.refreshTokenPromise = promise;
  },
  resetAuth (state) {
    state.token = null;
    state.userDetails = null;
    state.refreshTokenPromise = null;
    state.expiry = null;
    state.twoFactorCode = null;
  },
  showCountrySelection (state, showCountrySelection) {
    state.showCountrySelection = showCountrySelection;
  },
  setBillingPlanCancelled (state, value) {
    state.userDetails.is_billing_plan_cancelled = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
