import store from '@/store';
import {
  checkIntegrationExistence,
  attachCountryCodeToURLQuery,
  redirectToPreConnectedProvider,
  validateIntegrationType,
  createCompanyProviderFromCache,
  redirectNewUserToIntegrationWizard,
  goToStripe, goToUnsubscribe, middlewareForUpgradeAccount, selectBilling,
} from '@/helpers/route';

export default function () {
  const routesChildren = [];

  routesChildren.push(
    {
      path:        '',
      name:        'Home',
      component:   () => import(/* webpackChunkName: "app-children-home" */ '@/views/app/children/Home.vue'),
      beforeEnter: redirectNewUserToIntegrationWizard,
    },
    {
      path:        'integration-type/choose/:type',
      name:        'IntegrationTypeChoose',
      component:   () => import(/* webpackChunkName: "app-children-integration-type" */ '@/views/app/children/IntegrationType.vue'),
      beforeEnter: validateIntegrationType,
    },
    {
      path:      'integration',
      component: () => import(/* webpackChunkName: "app-children-integration" */ '@/views/app/children/Integration.vue'),
      children:  [
        {
          path:        'create/:integrationTypeId/:type',
          name:        'IntegrationCreate',
          component:   () => import(/* webpackChunkName: "components-integration-wizard" */ '@/views/app/children/IntegrationWizard.vue'),
          props:       (route) => ({
            integrationTypeId: Number(route.params.integrationTypeId),
          }),
          beforeEnter: createCompanyProviderFromCache,
        },
        {
          path:      ':integrationId',
          name:      'IntegrationEdit',
          component: () => import(/* webpackChunkName: "components-integration-wizard" */ '@/views/app/children/IntegrationWizard.vue'),
          props:     (route) => ({
            integrationId: Number(route.params.integrationId),
            openingStep:   route.params.openingStep,
          }),
        },
      ],
    },
    {
      path:        'preselect-integration/:integrationTypeId',
      name:        'PreselectIntegration',
      beforeEnter: checkIntegrationExistence,
    },
    {
      path:      'sync-history',
      name:      'SyncHistory',
      component: () => import(/* webpackChunkName: "app-children-home" */ '@/views/app/children/SyncHistory.vue'),
      props:     (route) => ({
        initialHistoryStatus:      route.params.syncHistoryStatus,
        initialHistoryIntegration: route.params.syncHistoryIntegration ?
          Number(route.params.syncHistoryIntegration) :
          undefined,
      }),
    },
    {
      path:      'connected-accounts',
      component: () => import(/* webpackChunkName: "app-children-connected-accounts-index" */ '@/views/app/children/ConnectedAccounts/Index.vue'),
      children:  [
        {
          path:      '',
          name:      'ConnectedAccounts',
          component: () => import(/* webpackChunkName: "app-children-connected-accounts-children-list" */ '@/views/app/children/ConnectedAccounts/children/List.vue'),
        },
        {
          path:      'add',
          name:      'ConnectedAccountAdd',
          component: () => import(/* webpackChunkName: "components-accounts-add-edit-screen" */ '@/views/app/children/AccountAddEditScreen.vue'),
          props:     (route) => ({ preselectProvider: route.params.preselectProvider }),
        },
        {
          path:      ':accountId',
          name:      'ConnectedAccountEdit',
          component: () => import(/* webpackChunkName: "components-accounts-add-edit-screen" */ '@/views/app/children/AccountAddEditScreen.vue'),
          props:     (route) => ({ accountId: Number(route.params.accountId) }),
        },
      ],
    },
    {
      path:      'profile',
      name:      'Profile',
      component: () => import(/* webpackChunkName: "app-children-profile" */ '@/views/app/children/Profile.vue'),
    },
    {
      path:       'request-access',
      name:       'Request access from Amaka',
      component: () => import(/* webpackChunkName: "app-children-request" */ '@/views/auth/children/RequestAccess.vue'),
    },
    {
      path:     'upgrade-account',
      redirect: { name: 'UpgradePlan' },
    },
    {
      path:        'billing/upgrade-plan/:integrationId?',
      name:        'UpgradePlan',
      component:   () => import(/* webpackChunkName: "app-children-billing" */ '@/views/app/children/UpgradePlan.vue'),
      beforeEnter: middlewareForUpgradeAccount,
    },
    {
      path:        'billing/account',
      name:        'Account',
      component:   () => import(/* webpackChunkName: "app-children-billing" */ '@/views/app/children/Account.vue'),
      beforeEnter: middlewareForUpgradeAccount,
    },
    {
      path:        'billing/payments',
      name:        'PaymentDetails',
      beforeEnter:  goToStripe,
      meta:         { type: 'payments' },
    },
    {
      path:        'billing/invoices',
      name:        'BillingInvoices',
      beforeEnter:  goToStripe,
      meta:         { type: 'invoices' },
    },
    {
      path:        'billing/unsubscribe',
      name:        'Unsubscribe',
      component:   () => import(/* webpackChunkName: "app-children-billing" */ '@/views/app/children/Account.vue'),
      beforeEnter:  goToUnsubscribe,
    },
    {
      path:      'after-connect',
      name:      'AfterConnect',
      component: () => import('@/views/app/children/AfterConnect.vue'),
    },
    {
      path:      'after-payment-redirect',
      name:      'AfterPaymentRedirect',
      component: () => import(/* webpackChunkName: "app-children-home" */ '@/views/app/children/AfterPaymentRedirect.vue'),
    },
    {
      path:        'country-selection',
      name:        'CountrySelection',
      component:   () => import(/* webpackChunkName: "app-children-home" */ '@/views/app/children/CountrySelection.vue'),
      beforeEnter: attachCountryCodeToURLQuery,
    },
    {
      path:        '/billing/:selectType/:plan/:cycle',
      name:        'SelectBilling',
      beforeEnter: selectBilling,
    },
  );
  if (!store.state.appConfig.isWhiteLabel) {
    routesChildren.push({
      path:      'reports',
      name:      'Reports',
      component: () => import(/* webpackChunkName: "app-children-home" */ '@/views/app/children/Report.vue'),
    });
  }

  return [
    {
      path:      '/app',
      component: () => import(/* webpackChunkName: "app-index-auth" */ '@/views/app/Index.vue'),
      meta:      {
        isProtected: true,
      },
      children:  routesChildren,
    },
    {
      path:      '/mobilehandler',
      name:       'MobileHandler',
      component: () => import(/* webpackChunkName: "app-index-auth" */ '@/views/app/children/MobileHandler.vue'),
      meta:      {
        isProtected: true,
      },
    },
    {
      path:        '/preconnect/:sourceProviderName/:targetProviderName',
      name:        'PreConnect',
      beforeEnter: redirectToPreConnectedProvider,
    },
    {
      path:        '/preconnect/:sourceProviderName',
      name:        'PreConnectWithOnlySourceProvider',
      beforeEnter: redirectToPreConnectedProvider,
    },
  ];
}
