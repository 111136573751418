// initial state
const state = {
  connected:           false,
  isJoinedPrivateRoom: false,
  privateRoomId:       null,
};

// actions
const actions = {
  SOCKET_connect ({ commit }) {
    commit('SOCKET_CONNECT');
  },
};

// mutations
const mutations = {
  SOCKET_CONNECT: (state) => {
    state.connected = true;
  },
  setJoinedPrivateRoom: (state, payload) => {
    state.isJoinedPrivateRoom = payload.joined;
    state.privateRoomId       = payload.roomId;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
