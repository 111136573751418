export default {
  bind: function (el, binding, vNode) {
    el.clickOutside = event => {
      if (!el.contains(event.target)) {
        // call method provided in v-click-outside value
        vNode.context[binding.expression](event);
        // event.stopPropagation()
      }
    };
    document.body.addEventListener('click', el.clickOutside);
  },
  unbind: function (el, binding, vNode) {
    // Remove Event Listeners
    document.removeEventListener('click', el.clickOutside);
    el.clickOutside = null;
  },
};
