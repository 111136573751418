import { INTEGRATION_TYPE_BACKEND_DICTIONARY, INTEGRATION_TYPE_INTEGRATION } from '@/helpers/enums';
import { Http }                                                              from './http';

export default {
  getIntegrationTypesList (
    limit,
    offset,
    orderBy,
    isDesc,
    integrationTypesMode = INTEGRATION_TYPE_INTEGRATION,
    providers = null,
    isExactMatch = false,
  ) {
    return Http.get('/integrations', {
      params: {
        limit:                  limit,
        offset:                 offset,
        order_by:               orderBy,
        is_desc:                isDesc,
        providers:              providers,
        is_exact_match:         isExactMatch,
        integration_types_mode: INTEGRATION_TYPE_BACKEND_DICTIONARY[integrationTypesMode],
      },
    });
  },

  getIntegrationTypeById (integrationId) {
    return Http.get(`/integrations/${integrationId}`, {
      params: {
        limit:  1,
        offset: 0,
      },
    });
  },
};
