import { existsOrElse } from '@/helpers/maybe';
import { INTEGRATION_TYPE_INTEGRATION, INTEGRATION_TYPES } from '@/helpers/enums';
import store from '@/store';
import providerApi from '@/api/provider';
import integrationApi from '@/api/integration';
import user from '@/api/user';
import billingApi from '@/api/billing';

export async function validateIntegrationType (to, from, next) {
  const integrationType = existsOrElse(to, 'params.type', INTEGRATION_TYPE_INTEGRATION);
  if (INTEGRATION_TYPES.includes(integrationType)) {
    // prevent dashboard redirect check as wizard has already opened
    store.commit('appConfig/setSkipRedirectionCreateIntegration', true);
    next();
  } else {
    next({ name: '404' });
  }
}

export async function createCompanyProviderFromCache (to, from, next) {
  if (from.query.cached) {
    try {
      await providerApi.autoCreateProvider(
        from.query.auth_id,
        from.query.state,
        from.query.timezone,
        from.query.company,
        from.query.providers,
        to.params.integrationTypeId,
        from.query.instance_id,
      );
    } catch (error) {
      await store.dispatch('systemFeedback/showApiError', {
        error:          error,
        defaultMessage: 'Error on creating provider.',
      }, { root: true });
    }
  }

  // prevent dashboard redirect check as wizard has already opened
  store.commit('appConfig/setSkipRedirectionCreateIntegration', true);
  next();
}

export async function checkIntegrationExistence (to, from, next) {
  try {
    const response = await integrationApi.getIntegrationsCount(store.getters['auth/companyId'], to.params.integrationTypeId);
    const count = existsOrElse(response, 'data.data.count', -1);
    if (count === 0) {
      next({
        name:   'IntegrationCreate',
        params: {
          'integrationTypeId': to.params.integrationTypeId,
        },
      });

      return;
    }
  } catch ($e) {
    // do nothing
  }
  next({ name: 'Home' });
}

export async function redirectNewUserToIntegrationWizard (to, from, next) {
  // only redirect when the first time coming to the path
  if (!store.state.appConfig.skipRedirectionCreateIntegration && store.state.appConfig.isWhiteLabel) {
    try {
      const response = await integrationApi.getIntegrationsCount(store.getters['auth/companyId']);
      const count = existsOrElse(response, 'data.data.count', -1);
      store.commit('appConfig/setSkipRedirectionCreateIntegration', true);
      if (count === 0) {
        next({
          name:   'IntegrationTypeChoose',
          params: {
            type: INTEGRATION_TYPE_INTEGRATION,
          },
        });

        return;
      }
    } catch ($e) {
      // do nothing
    }
  }

  store.commit('appConfig/setSkipRedirectionCreateIntegration', true);
  next();
}

export async function attachCountryCodeToURLQuery (to, from, next) {
  if (store.getters['auth/userCountryCode']) {
    next({ name: 'Home', query: to.query });

    return;
  }

  next();
}

export async function goToUnsubscribe (to, from, next) {
  await store.dispatch('systemFeedback/showConfirmModal', {
    title:           'Unsubscribe',
    content:         'Are you sure you want to unsubscribe from your premium subscription plan? <br/><br/> At the beginning of your upcoming billing cycle, your account will be downgraded to a free plan. Your active integrations could be affected.',
    variant:         'danger',
    confirmFunction: async () => {
      try {
        await user.unsubscribePlan();
        await store.dispatch('systemFeedback/showSimpleModal', {
          title:   'Unsubscribe',
          content: 'You have successfully unsubscribed from your premium subscription plan.',
        });
        await store.commit('auth/setBillingPlanCancelled', true);
      } catch (error) {
        await store.dispatch('systemFeedback/showApiError', error);
      }
    },
  });

  next({ name: 'Account' });
}

export async function goToStripe (to, from, next) {
  const userDetails = store.getters['auth/userDetails'];

  let routeType = null;

  if (to.meta.type !== undefined) {
    routeType = `?type=${to.meta.type}`;
  }

  if (userDetails && userDetails.billing_type === 'shopify') {
    await store.dispatch('systemFeedback/showApiError', { defaultMessage: 'To manage your billing and Shopify account settings, go to the Shopify subscription dashboard.' }, { root: true });

    next({ name: 'Account' });

    return;
  }

  user.getCustomerPortalSessionUrl(routeType)
    .then((response) => {
      const url = existsOrElse(response, 'data.data.url', null);
      if (url) {
        window.location.href = url;
      } else {
        next({ name: 'Home' });
      }
    })
    .catch((error) => {
      next({ name: 'Home' });
      store.dispatch('systemFeedback/showApiError', {
        error:          error,
        defaultMessage: 'Error on getting Stripe customer portal session.',
      }, { root: true });
    });
}

export async function redirectToPreConnectedProvider (to, from, next) {
  try {
    const response = await providerApi.getPreConnectUrl(
      to.params.sourceProviderName,
      to.params.targetProviderName,
      to.query,
    );
    const redirectUrl = existsOrElse(response, 'data.uri', undefined);
    if (redirectUrl !== undefined) {
      window.location.href = redirectUrl;

      return;
    }
  } catch ($e) {
    // do nothing
  }
  next({ name: 'Home' });
}

export async function selectBilling (to, from, next) {
  const isFreeUser = store.getters['auth/userPlanAccessLevel'] === 'free';
  if (isFreeUser) {
    next({ name: 'Home' });

    return;
  }

  try {
    const plan = to.params.plan + '_' + to.params.cycle;
    if (to.params.selectType === 'select-silent') {
      const response = await billingApi.getTrialSubscription(null, plan);
      const redirectUri = existsOrElse(response, 'data.data.uri', undefined);
      if (redirectUri !== undefined) {
        window.location.href = redirectUri;
      } else {
        next({ name: 'Home' });
      }
    } else {
      const response = await billingApi.getNewCheckoutSession(
        plan,
        null,
        null,
        null,
        false,
      );
      const responseData = existsOrElse(response, 'data.data', undefined);
      const redirectUri = responseData.uri;
      if (redirectUri !== undefined) {
        window.location.href = redirectUri;
      } else {
        next({ name: 'Home' });
      }
    }
  } catch (error) {
    this.showApiError({
      error:          error.message,
      defaultMessage: 'Fail to create checkout.',
    });
  }
}

export async function middlewareForUpgradeAccount (to, from, next) {
  const userPlanAccessLevel = store.getters['auth/userPlanAccessLevel'];

  if (userPlanAccessLevel === 'free') {
    next({ name: 'Home' });
  }

  next();
}
