<template>
  <b-modal v-model="showModal"
           lazy @hidden="closeFitbizModal"
           centered
           modal-class="fitbiz__modal"
           title=""
           @close="closeFitbizModal">
    <b-row class="fitbiz__modal--content">
      <b-col class="promotion-content" cols="12" xl="7">
        <b-row class="promotion-header">
          <b-col cols="12" sm="6">
            <svgicon icon="fitbiz/fitbiz-tile" class="svg-icon " original/>
          </b-col>
          <b-col class="text-right" cols="12" sm="6">
            <h1>Try Fitbiz!</h1>
            <svgicon icon="fitbiz/arrow-icon" class="svg-icon" original/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2>Feel your business' pulse in real time</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p>
              Watch your bottom-line grow, form healthy business habits by kicking your AI-tailored stretch goals and
              keep track of your operations in real time.
            </p>
          </b-col>
        </b-row>
        <b-row class="promotion-list">
          <b-col>
            <svgicon icon="fitbiz/icon-hearth" class="svg-icon" original/>
            <span>Real-time business heartbeat</span>
          </b-col>
        </b-row>
        <b-row class="promotion-list">
          <b-col>
            <svgicon icon="fitbiz/icon-pie-chart" class="svg-icon" original/>
            <span>AI-tailored stretch goals</span>
          </b-col>
        </b-row>
        <b-row class="promotion-list">
          <b-col>
            <svgicon icon="fitbiz/icon-chart" class="svg-icon" original/>
            <span>Real-time performance</span>
          </b-col>
        </b-row>
        <b-row class="promotion-download">
          <b-col cols="6" lg="5">
            <a href="https://amaka.com/fitbiz/download/ios" target="_blank">
              <img src="https://storage.googleapis.com/ipaas-on-gae.appspot.com/general/app-store-button.svg" alt="Fitbiz">
            </a>
          </b-col>
          <b-col cols="6" lg="5">
            <a href="https://amaka.com/fitbiz/download/android" target="_blank">
              <img src="https://storage.googleapis.com/ipaas-on-gae.appspot.com/general/google-play-button.svg" alt="Fitbiz">
            </a>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="promotion-mobile-arrow" cols="4">
        <h1>Try Fitbiz!</h1>
        <svgicon icon="fitbiz/arrow-icon" class="svg-icon" original/>
      </b-col>
      <b-col class="promotion-image" cols="8" sm="5"
             md="5" xl="5">
        <img src="https://storage.googleapis.com/ipaas-on-gae.appspot.com/general/fitbiz-mobile.png" alt="Fitbiz">
      </b-col>
    </b-row>
    <template #modal-footer>
      <svgicon icon="amaka-logo-white" style="width: 8rem;height: inherit;" original/>
      <p>From</p>
    </template>
  </b-modal>
</template>

<script>
  import { BModal, BRow, BCol } from 'bootstrap-vue';
  import '@/svg-icons/fitbiz/fitbiz-tile';
  import '@/svg-icons/fitbiz/arrow-icon';
  import '@/svg-icons/fitbiz/icon-hearth';
  import '@/svg-icons/fitbiz/icon-pie-chart';
  import '@/svg-icons/fitbiz/icon-chart';
  import '@/svg-icons/amaka-logo-white';
  import { mapMutations, mapState } from 'vuex';

  export default {
    name:     'FitbizModal',
    computed: {
      ...mapState('systemFeedback', [ 'isFitbizModalShowing' ]),
      showModal: {
        get () {
          return this.isFitbizModalShowing;
        },
        set (value) {
          return value;
        },
      },
    },
    methods: {
      ...mapMutations('systemFeedback', ['closeFitbizModal']),
    },
    components: {
      BModal,
      BRow,
      BCol,
    },
  };
</script>

<style lang="scss">
  .fitbiz__modal {
    color: white;
    .promotion-content {
      h1, h2 {
        font-size: 3.5rem;
        margin-right: 20%;
        color: white;
      }
      h2 {
        font-size: 2.5rem;
        margin: 2rem 0;
      }
      p, span {
        margin: 0;
        font-size: 1.8rem;
        line-height: 2.5rem;
      }
      @media (max-width: 576px) {
        h1, h2 {
          font-size: 2rem;
          text-align: left;
          margin: 20px 0;
        }
        h2 {
          font-size: 1.2rem;
        }
        p {
          font-size: 0.8rem;
          line-height: 1.3rem;
        }
        span {
          font-size: 1.2rem;
          line-height: 0;
          padding: 0 10px;
        }
      }
    }
    .promotion-header {
      svg {
        width: 150px;
        height: inherit;
      }
      @media (max-width: 1199px) {
        .text-right {
          h1 {
            margin: 0;
          }
          svg {
            display: none;
          }
        }
      }
      @media (max-width: 576px) {
        h1 {
          display: none;
        }
        svg {
          width: 70px;
        }
      }
    }
    &--content {
      font-size: 1rem;
      textarea {
        overflow: auto !important;
        &::placeholder, &::-webkit-input-placeholder {
          color: $secondary !important;
        }
      }
      &-label {
        p {
          margin: 0;
        }
      }
      .col-form-label {
        padding-top: 0;
      }
    }
    .promotion-download {
      & > div {
        padding: 30px 15px 15px;
      }
      img {
        max-height: 70px;
      }
      @media (max-width: 1699px) {
        img {
          max-height: 50px;
        }
      }
      @media (max-width: 576px) {
        & > div {
          padding: 10px 0 15px;
        }
        img {
          max-height: 50px;
          width: 100%;
        }
      }
    }
    .promotion-list {
      margin: 20px auto;
      svg {
        width: 5%;
        height: inherit;
        margin-top: -18px;
      }
      span {
        padding: 0 15px;
      }
      .col {
        padding: 0;
      }
      @media (max-width: 576px) {
        margin: 10px auto;
        svg {
          margin-top: -8px;
        }
        span {
          font-size: 0.9rem;
          padding: 0 10px;
        }
      }
    }
    .promotion-image {
      margin-top: 80px;
      img {
        width: 100%;
      }
      @media (max-width: 1199px) {
        margin: 50px 0 20px;
      }
      @media (max-width: 576px) {
        margin-top: 20px;
      }
    }
    .modal-header {
      background-color: black;
      border: 0;
      padding-bottom: 0;
      .modal-title, .close, .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
        color: black;
      }
      .close {
        color: black;
        background-color: white;
        opacity: 1;
        border-radius: 100%;
        margin: 0;
        padding: 5px 23px;
        line-height: inherit;
        font-size: 2rem;
        @media (max-width: 576px) {
          padding: 0 10px 2px;
          font-size: 1rem;
        }
      }
    }
    .modal-body {
      padding: 1rem 3rem 0;
      @media (max-width: 576px) {
        padding: 0 1rem;
      }
    }
    .modal-content {
      padding: 0;
      background-color: black;
    }
    .modal-footer {
      border-top: 0;
      flex-direction: column;
      align-items: flex-end;
      padding: 0 2rem 2rem;
      svg {
        width: 8rem;
      }
      @media (max-width: 576px) {
        padding: 0 5rem 2rem;
        svg {
          width: 6rem;
        }
      }
    }
    .promotion-mobile-arrow {
      display: none;
      @media (max-width: 576px) {
        display: block;
        text-align: right;
        margin-top: 10px;
        h1 {
          color: white;
          font-size: 1.2rem;
        }
        svg {
          width: 100%;
          height: 20px;
          position: absolute;
          right: -40px;
        }
      }
    }
    @media (min-width: 576px) {
      .modal-dialog {
        max-width: 60vw !important;
      }
    }
  }
</style>
