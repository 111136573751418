<template>
  <b-modal v-model="isModalShowing"
           centered
           modal-class="choice__modal"
           lazy @hidden="removeChoiceModal"
           title="Unlock Premium Features"
           :header-bg-variant="modalDetails.variant"
           @close="onCloseButtonClick">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <b-container fluid>
      <b-row class="mb-4">
        <div v-if="userPlanAccessLevel === 'premium'">
          You have selected a premium feature that is only available with a higher plan.
          You can upgrade now.
        </div>
        <div v-else>
          You have selected a premium feature that is only available with a paid plan.
          You can upgrade now or start your 7-day free trial.
        </div>
      </b-row>
      <b-row class="mb-1">
        <radio-group-card :schema="planOptions" v-model="optionSelected" is-horizontal
                          inner-option-class="card-item">
          <template #default="slotProps">
            <div class="ml-2">
              <p>
                <b class="text-break title">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span class="float-left" v-html="sanitizeHTMLLocally(slotProps.option.name)"></span>
                  <b-badge :variant="slotProps.option.badge.variant" class="float-right" v-if="!!slotProps.option.badge.text">{{ slotProps.option.badge.text }}</b-badge>
                </b>
              </p>
              <p class="float-left text-break description"><small>{{ slotProps.option.description }}</small></p>
            </div>
          </template>
        </radio-group-card>
      </b-row>
      <b-row class="text-center" align-v="center">
        <b-col align-self="center">
          <b-link :to="{ name: 'UpgradePlan', query: allPlansQuery }" target="_blank" rel="noopener noreferrer">
            <small>Click Here to see all Amaka plans available <b-icon-arrow-up-right-circle-fill/></small>
          </b-link>
        </b-col>
      </b-row>
    </b-container>

    <template #modal-footer>
      <b-container>
        <b-row class="text-center" align-v="center">
          <b-col align-self="center">
            <b-button variant="primary" @click="onPrimaryButtonClick">
              {{ modalDetails.okText }}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="text-center" align-v="center">
          <b-col align-self="center">
            <b-button variant="light" @click="onSecondaryButtonClick">
              {{ modalDetails.cancelText }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { BModal, BRow, BCol, BContainer, BButton, BBadge, BIconArrowUpRightCircleFill, BLink } from 'bootstrap-vue';
import RadioGroupCard from '@/components/general/inputs/RadioGroupCard';
import { PLAN_TRIAL_ID, PLAN_PREMIUM_ID } from '@/helpers/enums';
import SanitizerMixins from '@/mixins/SanitizerMixins';

export default {
  name: 'ChoiceModal',

  mixins: [SanitizerMixins],

  data () {
    return {
      isModalShowing: false,
      optionSelected: PLAN_PREMIUM_ID,
    };
  },

  computed: {
    ...mapGetters('systemFeedback', ['currentChoiceModal']),
    ...mapGetters('auth', ['hasUserUsedTrial', 'newSubscriptionDiscount', 'userPlanAccessLevel']),
    modalDetails () {
      return this.currentChoiceModal || {};
    },
    allPlansQuery () {
      if (this.modalDetails.showDiscountOption) {
        return { discount: true };
      }

      return {};
    },
    planOptions () {
      let options = [];

      options.push({
        'name':        'Upgrade Now',
        'description': 'Upgrade now to unlock premium features.',
        'value':       PLAN_PREMIUM_ID,
        'badge':       {},
      });

      if (this.modalDetails.showDiscountOption) {
        options[0] = { ...options[0],
          ...{
            'description': 'Upgrade now to unlock premium features. You’ll still be charged at the end of the trial period. 5% discount will be applied only to your first bill.',
            'badge':       {
              'variant': 'success',
              'text':    this.newSubscriptionDiscount ? `${this.newSubscriptionDiscount}% OFF` : '',
            },
          },
        };
      }

      if (!this.hasUserUsedTrial && this.modalDetails.showTrialOption) {
        options.push({
          'name':        'Free Trial',
          'description': 'Try Amaka premium features for free for 7 days. ' +
                           'Upgrade at any time, or downgrade to the free plan after the trial period.',
          'value':       PLAN_TRIAL_ID,
          'badge':       {
            'variant': 'primary',
            'text':    'No Credit Card Required',
          },
        });
      }

      return {
        property:         'plans',
        options_track_by: 'value',
        validation:       'required',
        options_label:    'name',
        options:          options,
      };
    },
  },

  methods: {
    ...mapMutations('systemFeedback', ['removeChoiceModal']),
    onPrimaryButtonClick () {
      if (this.currentChoiceModal && this.currentChoiceModal.confirmFunction) {
        this.currentChoiceModal.confirmFunction(this.optionSelected);
      }
      this.isModalShowing = false;
    },
    onSecondaryButtonClick (event) {
      this.isModalShowing = false;
    },
    onCloseButtonClick (event) {
      this.isModalShowing = false;
    },
  },

  watch: {
    currentChoiceModal (newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.optionSelected = PLAN_PREMIUM_ID;
          this.isModalShowing = true;
        });
      }
    },
  },

  components: {
    BModal,
    BRow,
    BCol,
    BContainer,
    BButton,
    BBadge,
    BIconArrowUpRightCircleFill,
    BLink,
    RadioGroupCard,
  },
};
</script>

<style lang="scss">
  .choice__modal {
    p, a {
      line-height: 1em;
      padding: 5px 0;

      small {
        font-size: 0.8em;
      }

      .title {
        font-size: 1.3em;
      }

      &.description {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    b small {
      font-weight: bolder;
    }

    .modal-header {
      background-color: #48C8DF;

      .modal-title, .close, .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
        color: $white;
      }
    }

    .modal-footer {
      border-top: none !important;
    }

    .btn.btn-light {
      background-color: $white;
      border-color: $white;
      color: $blue;
    }

    .radio-group-card {
      width: 100%;
    }

    .card-item {
      padding: 10px 40px 0;
      width: 100%;
    }

    .custom-control.custom-radio {
      margin-right: 0;
      width: 100%;

      .custom-control-label {
        width: 100%;

        div {
          width: 100%;
        }
      }
    }

    .radio-group-card .custom-control-label::before, .radio-group-card .custom-control-label::after {
      top: 0.4em;
    }
  }
</style>
