import { Http } from './http';
import store from '@/store';
import Vue from 'vue';
const newVue = new Vue();

export default {
  async generateRecaptcha () {
    // (optional) Wait until recaptcha has been loaded.
    await newVue.$recaptchaLoaded();

    return newVue.$recaptcha('amaka');
  },
  // add isNoRetry for interceptor to not run into loop
  async login (email, password) {
    return Http.post('auth/login', {
      email:              email,
      password:           password,
      origin:             store.getters['appConfig/origin'],
      two_factor_code:    store.getters['auth/twoFactorCode'],
      recaptcha_response: await this.generateRecaptcha(),
    }, {
      isNoRetry: true,
    });
  },
  logout () {
    return Http.post('auth/logout');
  },
  refreshToken () {
    return Http.post('auth/refresh', {
      isNoRetry: true,
    });
  },
  async forgotPassword (email, callback) {
    return Http.get('auth/forgot', {
      params: {
        email:              email,
        callback:           callback,
        recaptcha_response: await this.generateRecaptcha(),
      },
    });
  },
  resetPassword (email, token, password) {
    return Http.post('auth/reset', {
      email:             email,
      token:             token,
      password:          password,
    });
  },
  async register (user, company, callback, next = null) {
    return Http.post('auth/register', {
      user,
      company,
      callback,
      next,
      origin:             store.getters['appConfig/origin'],
      recaptcha_response: await this.generateRecaptcha(),
    });
  },
  verify (code, userId) {
    return Http.put('auth/verify', {
      code:              code,
      user_id:           userId,
    });
  },
  resend (userId, callback, next = null) {
    return Http.get('auth/resend', {
      params: {
        user_id:           userId,
        callback,
        next,
      },
    });
  },
  validate (email) {
    return Http.get('auth/validate', {
      params: {
        email:             email,
        origin:            store.getters['appConfig/origin'],
      },
    });
  },
  approveRequestAccess ({ reportType, connector, requestData }) {
    return Http.post('auth/approve_request_access', {
      report_type:       reportType,
      connector:         connector,
      request_data:      requestData,
    });
  },
};
