import { Http } from './http';

export default {
  getUsersById (userId) {
    return Http.get('/users/' + userId);
  },
  updateUserPasswordById (userId, details) {
    details.old_password = details.old_password ? details.old_password : undefined;
    details.new_password = details.new_password ? details.new_password : undefined;
    details.confirm_password = details.confirm_password ? details.confirm_password : undefined;

    return this.updateUsersById(userId, details);
  },
  updateUsersById (userId, details) {
    return Http.put('/users/' + userId, details);
  },
  getCustomerPortalSessionUrl (query = null) {
    return Http.get(`/billing/customer-portal${query}`);
  },
  unsubscribePlan () {
    return Http.delete('/billing/unsubscribe');
  },
};
