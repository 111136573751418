import moment           from 'moment';
import { existsOrElse } from '@/helpers/maybe';
import store            from '@/store';

export const scrollTo = (element, scrollPixels, duration, isHorizontalScroll = false) => {
  const scrollPos = isHorizontalScroll ? element.scrollLeft : element.scrollTop;
  // Condition to check if scrolling is required
  // Get the start timestamp
  const startTime =
    'now' in window.performance ?
      performance.now() :
      new Date().getTime();

  function scroll (timestamp) {
    // Calculate the timeelapsed
    const timeElapsed = timestamp - startTime;
    // Calculate progress
    const progress = Math.min(timeElapsed / duration, 1);
    // Set the scrolleft
    if (isHorizontalScroll) {
      element.scrollLeft = scrollPos + scrollPixels * progress;
    } else {
      element.scrollTop = scrollPos + scrollPixels * progress;
    }
    // Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
    if (timeElapsed < duration) {
      // Request for animation
      window.requestAnimationFrame(scroll);
    }
  }

  // Call requestAnimationFrame on scroll function first time
  window.requestAnimationFrame(scroll);
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';

  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const guessTimezone = () => {
  return moment.tz.guess(true);
};

export const convertToSlug = (title) => {
  if (!title) {
    return title;
  }

  return title.toLocaleLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '-');
};

export const refactorSocialUrl = (route, url) => {
  const next = existsOrElse(route, 'query.successNext', null);
  const source = existsOrElse(route, 'query.source', null);
  if (next) {
    url.searchParams.set('next', next);
  }
  if (source) {
    url.searchParams.set('source', source);
  }
  url.searchParams.set('redirect_origin', window.location.origin);
  url.searchParams.set('timezone', guessTimezone());
  url.searchParams.set('origin', store.getters['appConfig/origin']);

  return url;
};

export const getCurrentUrl = () => {
  return window.location.pathname;
};

export const toPrice = (amount, currency = '$') => {
  let price = parseFloat(amount).toFixed(2);
  if (price < 0) {
    price *=  -1;

    return `(${currency}${price})`;
  } else {
    return `${currency}${price}`;
  }
};
