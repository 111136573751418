import Vue from 'vue';
import App from './App.vue';
import routerBuild from '@/router';
import store from './store';

// vee validate
import { ValidationObserver, ValidationProvider, extend, localize, setInteractionMode } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';
import VueWebsocket from 'vue-websocket/src/index';

// directly importing library src file to prevent minified file gets further mangled and throw errors subsequently after build.
import VueSocketIO from 'vue-socket.io';
import SvgIcon from 'vue-svgicon';
import Notifications from 'vue-notification';
import '@/helpers/momentLocales';
import vueMoment from 'vue-moment';
import * as moment from 'moment-timezone';
import * as filters from '@/filters';
import ClickOutside from './directives/ClickOutside';
import VueGtm from 'vue-gtm';
import ResponsivePlugin from './plugins/ResponsviePlugin';
import { CollapsePlugin } from 'bootstrap-vue';

import './styles/app.scss';

import errorHandler from './helpers/error-reporting';
import { VueReCaptcha } from 'vue-recaptcha-v3';

Vue.use(CollapsePlugin);

Vue.use(VueReCaptcha, {
  siteKey:       process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge:            true,
    explicitRenderParameters: {
      badge: 'bottomleft',
    },
  },
});

if (process.env.NODE_ENV === 'production') {
  // only log error when the app is in production
  // initialise Google Stackdriver error logger
  errorHandler.start({
    key:       'AIzaSyCGnIEWzamMgpP0nnJIXu2EP746zsrvSCg',
    projectId: 'ipaas-on-gae',
    service:   'amaka-ui',
    version:   process.env.VUE_APP_MODE,
  });
  // report any vue caught errors
  Vue.config.errorHandler = function (err) {
    errorHandler.report(err);
  };

  // to pass user id to error logging when the user is logged in.
  store.watch(
    (state, getters) => getters['auth/userId'],
    (newValue) => {
      errorHandler.setUser(newValue ? newValue.toString() : undefined);
    },
  );
}

Vue.config.productionTip = false;

// install rules, localization and providers for vee-validate 3
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
localize('en', en);
setInteractionMode('eager');
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.use(new VueSocketIO({
  debug:      process.env.NODE_ENV !== 'production',
  connection: process.env.VUE_APP_WS_URL,
  vuex:       {
    store,
    actionPrefix:   'SOCKET_',
    mutationPrefix: 'SOCKET_',
  },
}));

Vue.use(VueWebsocket, process.env.VUE_APP_WS_URL);
Vue.use(vueMoment, { moment });
Vue.use(SvgIcon);
Vue.use(Notifications);
Vue.use(ResponsivePlugin);

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.directive('click-outside', ClickOutside);

async function newVue () {
  let isServiceUnavailable = false;
  try {
    await store.dispatch('appConfig/setAppDomain');
    await store.dispatch('appConfig/getAppConfig');
    await store.dispatch('appConfig/getPlans');
  } catch (exception) {
    console.error(exception);
    isServiceUnavailable = true;
  }
  const router = routerBuild(isServiceUnavailable);
  Vue.use(VueGtm, {
    id:        'GTM-KB7C7M6',
    debug:     process.env.NODE_ENV !== 'production',
    vueRouter: router,
  });
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
}

newVue();

export const bus = new Vue();
