import billingApi from '@/api/billing';
import store from '@/store';
import { existsOrElse } from '@/helpers/maybe';

export const checkoutStripeTrialSubscription = async (companyIntegrationId) => {
  const response = await billingApi.getTrialSubscription(companyIntegrationId);
  await store.dispatch('auth/refreshToken', { isForce: true });
  // this url is coming from the BE when the billing type is shopify instead of stripe
  const redirectUri = existsOrElse(response, 'data.data.uri', undefined);
  if (redirectUri !== undefined) {
    window.location.href = redirectUri;
  }
};

export const checkoutStripePlanSubscription = async (plan, companyIntegrationId, actionType, redirectUrl, isDiscountApplied) => {
  const response = await billingApi.getNewCheckoutSession(plan, companyIntegrationId, actionType, redirectUrl, isDiscountApplied);
  const responseData = existsOrElse(response, 'data.data', undefined);
  const notification = existsOrElse(response, 'data.data.notification', undefined);
  // this url is coming from the BE when the billing type is shopify instead of stripe
  const redirectUri = responseData.uri;
  if (redirectUri !== undefined) {
    window.location.href = redirectUri;

    return;
  }

  if (notification === undefined) {
    await store.dispatch('systemFeedback/showToast', { variant: 'error', content: 'Something went wrong' });
  }
};
