import { Http } from './http';

export default {
  getProviders () {
    return Http.get('/providers', {});
  },
  autoCreateProvider (authId, state, timezone, company, providers, integrationId, instanceId = null) {
    return Http.post(
      '/companies_providers/create_from_cache',
      {
        auth_id:        authId,
        integration_id: integrationId,
        instance_id:    instanceId,
        company,
        providers,
        state,
        timezone,
      },
    );
  },
  getPreConnectUrl (sourceProviderName, targetProviderName, query) {
    let queryParams = `provider=${sourceProviderName}`;
    if (targetProviderName) {
      queryParams += `&partner=${targetProviderName}`;
    }

    const queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&');
    const completeQueryString = queryString ? `${queryParams}&${queryString}` : queryParams;

    return Http.get(`/preconnect?${completeQueryString}`);
  },
};
