import { INTEGRATION_TYPE_BACKEND_DICTIONARY, INTEGRATION_TYPE_INTEGRATION } from '@/helpers/enums';
import { Http }                                                              from './http';

export default {
  createIntegration (companyId, integrationId, chosenCompanyProvidersId, modules, name, isDraft) {
    return Http.post(`/companies/${companyId}/companies_integrations`, {
      integration_id:              integrationId,
      chosen_company_providers_id: chosenCompanyProvidersId,
      modules:                     modules,
      name:                        name,
      is_draft:                    isDraft,
    });
  },

  getIntegrationModules (companyIntegrationId) {
    return Http.get(`/companies_integrations/${companyIntegrationId}/modules`);
  },

  getIntegrationsCount (companyId, integrationTypeId) {
    return Http.get(`/companies/${companyId}/companies_integrations_count`, {
      params: {
        integrationId: integrationTypeId,
      },
    });
  },

  getRedirectUrl (companyId, providerId, callback = window.location.href, providersFilter = '') {
    return Http.get(`/companies/${companyId}/provider/${providerId}/connect`, {
      params: {
        filter:   providersFilter.toLowerCase(),
        callback: callback,
      },
    });
  },

  getIntegrations (limit, offset, companyId, integrationTypesMode = INTEGRATION_TYPE_INTEGRATION) {
    return Http.get(`/companies/${companyId}/companies_integrations`, {
      params: {
        limit:                  limit,
        offset:                 offset,
        companyId:              companyId,
        integration_types_mode: INTEGRATION_TYPE_BACKEND_DICTIONARY[integrationTypesMode], // source_to_target,source_to_report,legacy
      },
    });
  },

  getIntegrationById (companyId, companyIntegrationId, includeAdvanced = true, includeUpgradeInfo = true) {
    return Http.get(`/companies/${companyId}/companies_integrations/${companyIntegrationId}`, {
      params: {
        include_advanced:     includeAdvanced,
        include_upgrade_info: includeUpgradeInfo,
      },
    });
  },

  updateIntegrationById (companyId, companyIntegrationId, details, isApplyingPreset = false, isRevokingLegacy = false, includeUpgradeInfo = false) {
    return Http.put(`/companies/${companyId}/companies_integrations/${companyIntegrationId}`, details, {
      params: {
        is_applying_preset:   isApplyingPreset,
        is_revoking_legacy:   isRevokingLegacy,
        include_upgrade_info: includeUpgradeInfo,
      },
    });
  },

  checkUpdateIntegrationById (companyId, companyIntegrationId, details, isApplyingPreset = false, isRevokingLegacy = false, includeUpgradeInfo = false) {
    return Http.post(`/companies/${companyId}/companies_integrations/${companyIntegrationId}/check`, details, {
      params: {
        is_applying_preset:   isApplyingPreset,
        is_revoking_legacy:   isRevokingLegacy,
        include_upgrade_info: includeUpgradeInfo,
      },
    });
  },

  activateIntegrationById (companyId, companyIntegrationId) {
    return Http.post(`/companies/${companyId}/companies_integrations/${companyIntegrationId}/activate`);
  },

  deleteIntegration (companyIntegrationId) {
    return Http.delete(`/companies_integrations/${companyIntegrationId}`);
  },

  resetIntegration (companyId, companyIntegrationId) {
    return Http.post(`/companies/${companyId}/companies_integrations/${companyIntegrationId}/reset`);
  },

  cloneIntegration (companyIntegrationId) {
    return Http.post(`/companies_integrations/${companyIntegrationId}/clone`);
  },

  getCompaniesIntegrationsModulesById (companyId, companyIntegrationId, moduleID, dynamicFormType) {
    return Http.get(`/companies/${companyId}/companies_integrations/${companyIntegrationId}/modules/${moduleID}`,
      {
        params: {
          dynamic_form_type: dynamicFormType,
        },
      },
    );
  },

  updateCompaniesIntegrationsModulesById (companyId, companyIntegrationId, moduleID, dynamicFormType, formData) {
    return Http.put(`/companies/${companyId}/companies_integrations/${companyIntegrationId}/modules/${moduleID}`,
      {
        dynamic_form_type: dynamicFormType,
        data:              formData,
      },
    );
  },

  checkCompanyIntegrationUserNeedUpgrade (companyId, companyIntegrationId, moduleID, dynamicFormType, formData) {
    return Http.post(`/companies/${companyId}/companies_integrations/${companyIntegrationId}/modules/${moduleID}/check`,
      {
        dynamic_form_type: dynamicFormType,
        data:              formData,
      },
    );
  },

  checkCompanyIntegrationHasValidSKUs (companyIntegrationId, formData) {
    return Http.get(`/companies_integrations/${companyIntegrationId}/check_sku`);
  },

  resyncFailedSchedulers (companyId, selections) {
    return Http.post(`/companies/${companyId}/companies_integrations/resync`, selections);
  },

  refreshCompaniesIntegrationSettings (companyId, companyIntegrationId) {
    return Http.post(`/companies/${companyId}/companies_integrations/${companyIntegrationId}/refresh`);
  },

  syncCompaniesIntegrationSettings (companyId, companyIntegrationId) {
    return Http.post(`/companies/${companyId}/companies_integrations/${companyIntegrationId}/syncinfo`);
  },

  // TODO: change base URL
  getCompaniesIntegrationPayRateSettings (companyIntegrationId, limit, offset) {
    return Http.get(`/companies_integrations/${companyIntegrationId}/pay_rate_settings`, {
      // baseURL: 'http://localhost:3000/v1',
      params: {
        limit:  limit,
        offset: offset,
      },
    });
  },

  createCompaniesIntegrationPayRateSettings (companyIntegrationId, companiesIntegrationPayRateSetting) {
    return Http.post(`/companies_integrations/${companyIntegrationId}/pay_rate_settings`,
      companiesIntegrationPayRateSetting, {
        // baseURL: 'http://localhost:3000/v1'
      });
  },

  updateCompaniesIntegrationPayRateSettings (companyIntegrationId, companiesIntegrationPayRateSetting) {
    return Http.put(`/companies_integrations/${companyIntegrationId}/pay_rate_settings/${companiesIntegrationPayRateSetting.id}`, companiesIntegrationPayRateSetting,
      {
        // baseURL: 'http://localhost:3000/v1'
      },
    );
  },

  deleteCompaniesIntegrationPayRateSettings (companyIntegrationId, companiesIntegrationPayRateSettingId) {
    return Http.delete(`/companies_integrations/${companyIntegrationId}/pay_rate_settings/${companiesIntegrationPayRateSettingId}`,
      {
        // baseURL: 'http://localhost:3000/v1'
      },
    );
  },

  getCompaniesIntegrationPayRates (companyIntegrationId) {
    return Http.get(`/companies_integrations/${companyIntegrationId}/pay_rates`, {
      // baseURL: 'http://localhost:3000/v1'
    });
  },

  cloneCompaniesIntegrationPayRateSettings (companyIntegrationId, companiesIntegrationPayRateSettingId) {
    return Http.post(`/companies_integrations/${companyIntegrationId}/pay_rate_settings/${companiesIntegrationPayRateSettingId}`,
      {
        // baseURL: 'http://localhost:3000/v1'
      },
    );
  },

  // Module presets
  getIntegrationModulePreset (integrationId, moduleId) {
    return Http.get(`/presets/integration/${integrationId}/module/${moduleId}`);
  },

  applyPresetConfig (companyIntegrationId, moduleId, presetId) {
    return Http.post(`/company_integrations/${companyIntegrationId}/preset`,
      {
        module_id: moduleId,
        preset_id: presetId,
      },
    );
  },

  // Manual Module Process
  manualModuleProcess (companyIntegrationId, fromDate, toDate, timezone) {
    return Http.post(`/company_integrations/${companyIntegrationId}/manual_module_process`,
      {
        date_from: fromDate,
        date_to:   toDate,
        timezone:  timezone,
      },
    );
  },
};
