import md5 from 'md5';

export const generateGravatarUrlForEmail = (email) => {

  const gravatarUrl = 'https://www.gravatar.com/avatar/';

  /** More information about gravatar parameters http://en.gravatar.com/site/implement/images/ */
  const gravatarUrlParameters = {
    s: '55', // Size of the image
    d: '404', // Default image if no gravatar is found
  };

  function generateMD5Hash (email) {
    return md5(email);
  }

  function parseParameters (parameters) {
    return Object.keys(parameters).map((key) => {
      return `${key}=${parameters[key]}`;
    }).join('&');
  }

  return gravatarUrl + generateMD5Hash(email) + '?' + parseParameters(gravatarUrlParameters);
};
