export const INTEGRATION_TYPE_INTEGRATION        = 'integration';
export const INTEGRATION_TYPE_REPORT             = 'report';
export const INTEGRATION_TYPE_LEGACY             = 'legacy';
export const INTEGRATION_TYPE_FITBIZ             = 'fitbiz';
export const INTEGRATION_TYPE_DOCUSIGN           = 'docusign';
export const INTEGRATION_TYPE_ANY                = 'any';
export const INTEGRATION_TYPES = [INTEGRATION_TYPE_INTEGRATION, INTEGRATION_TYPE_REPORT, INTEGRATION_TYPE_LEGACY, INTEGRATION_TYPE_ANY, INTEGRATION_TYPE_DOCUSIGN];
export const INTEGRATION_TYPE_BACKEND_DICTIONARY = {
  [INTEGRATION_TYPE_INTEGRATION]: 'source_to_target',
  [INTEGRATION_TYPE_REPORT]:      'source_to_report',
  [INTEGRATION_TYPE_DOCUSIGN]:    'source_to_docusign',
  [INTEGRATION_TYPE_LEGACY]:      'legacy',
};
export const PLAN_ACCESS_LEVEL_FREE           = 'free';
export const PLAN_ACCESS_LEVEL_BASIC          = 'basic';
export const PLAN_ACCESS_LEVEL_ADVANCED       = 'premium';
export const PLAN_ACCESS_LEVEL_ADVANCED_PLUS  = 'premium_plus';

export const PLAN_TRIAL_ID = 0;
export const PLAN_PREMIUM_ID = 1;

export const WIZARD_OPTION_EXPRESS = 'express';
export const WIZARD_OPTION_ADVANCED = 'advanced';
export const WIZARD_OPTION_GUIDED = 'guided';

export const AUTH_METHOD_TYPE_SCHEMA = '0';
export const AUTH_METHOD_TYPE_SOCKET_SCHEMA = '1';
export const AUTH_METHOD_TYPE_SOCKET = '2';
export const AUTH_METHOD_TYPE_REDIRECTION = '3';

export const MODULE_ID_INVENTORY = 25;
