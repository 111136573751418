var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"radio-group-card"},[_c('ValidationProvider',{ref:"ValidationProvider",attrs:{"name":_vm.schema.label,"vid":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('div',{staticClass:"radio-group-card__list"},[_c('b-form-radio-group',{class:{
                            'row': _vm.isHorizontal
                          },attrs:{"name":_vm.name,"state":errors[0] ? false : null},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.schema.options),function(option){
                          var _obj;
return _c('div',{key:option[_vm.optionsTrackBy],class:( _obj = {'col-12': _vm.isHorizontal && !_vm.innerOptionClass}, _obj[_vm.innerOptionClass] = true, _obj )},[_c('b-card',{staticClass:"radio-group-card__option mb-2",class:{'selected': _vm.model===option[_vm.optionsTrackBy], 'disabled': option[_vm.optionsDisable]},attrs:{"id":_vm.getCardId(option)},on:{"click":function($event){_vm.$refs[option[_vm.optionsTrackBy]][0].$el.childNodes[0].click()}}},[_c('b-form-radio',{ref:option[_vm.optionsTrackBy],refInFor:true,attrs:{"disabled":_vm.schema.disabled || option[_vm.optionsDisable],"value":option[_vm.optionsTrackBy]}},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(option[_vm.optionsLabel])+" ")]},{"normalLabel":option[_vm.optionsLabel],"option":option})],2)],1),_vm._t("after-option",null,{"option":option})],2)}),0)],1),_c('b-form-invalid-feedback',{attrs:{"force-show":invalid}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }