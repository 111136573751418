import axios from 'axios';
import Deferred from 'es6-deferred';
import errorHandler from '@/helpers/error-reporting';

import store from '@/store';
import { dispatchResponseMessage } from '@/helpers/http-responses';

let activeRequestCounts = 0;
let unauthorisedDeferredRequests = [];

const Http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

let increaseActiveRequestCounts = () => {
  activeRequestCounts++;
  store.commit('systemFeedback/setActiveRequest', activeRequestCounts);
};

let decreaseActiveRequestCounts = () => {
  activeRequestCounts--;
  if (activeRequestCounts < 0) {
    activeRequestCounts = 0;
  }
  store.commit('systemFeedback/setActiveRequest', activeRequestCounts);
};

Http.interceptors.request.use((config) => {
  increaseActiveRequestCounts();
  const token = store.getters['auth/token']();
  if (token) {
    config.headers['authorization'] = 'Bearer ' + token;
  }
  if (process.env.VUE_APP_ACCEPT_HEADER) {
    config.headers['accept'] = process.env.VUE_APP_ACCEPT_HEADER;
  }
  if (process.env.VUE_APP_PHPSTORM_DEBUG_KEY) {
    config.params = { ...config.params, 'XDEBUG_SESSION_START': process.env.VUE_APP_PHPSTORM_DEBUG_KEY };
  }

  return config;
}, (err) => {
  return Promise.reject(err);
});

Http.interceptors.response.use((response) => {
  decreaseActiveRequestCounts();

  dispatchResponseMessage(response);

  return response;
}, (error) => {
  decreaseActiveRequestCounts();
  // if the user is unauthorised and the request is not no retry request (e.g. login, refresh token, or any previous retry request)
  if (error && error.response && error.response.status === 401 && error.config && !error.config.isNoRetry) {
    let deferred = new Deferred();

    unauthorisedDeferredRequests.push(deferred);

    // When the session recovered, make the same backend call again and chain the request
    if (unauthorisedDeferredRequests.length <= 1) {
      store.dispatch('auth/refreshToken', { isForce: true, showExpireToast: true })
        .then(function (response) {
          for (let i = 0; i < unauthorisedDeferredRequests.length; i++) {
            unauthorisedDeferredRequests[i].resolve();
          }
          unauthorisedDeferredRequests = [];
        }, function () {
          for (let i = 0; i < unauthorisedDeferredRequests.length; i++) {
            unauthorisedDeferredRequests[i].reject();
          }
          unauthorisedDeferredRequests = [];
        });
    }

    return deferred.promise.then(function () {
      // retry execution on failed item
      error.config.isNoRetry = true;

      return Http(error.config);
    }, function () {

      return Promise.reject(error);
    });
  }
  if (process.env.NODE_ENV === 'production') {
    // only log API error when the app is in production
    errorHandler.report(new Error(`API ${error.config.url} ${error.config.method} ${error.response.status} request_data:${error.config.data} request_params:${JSON.stringify(error.config.params)} response: ${JSON.stringify(error.response.data)}`));
  }

  return Promise.reject(error);
});

export { Http };
