/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fitbiz/icon-hearth': {
    width: 69.438,
    height: 56.847,
    viewBox: '0 0 69.438 56.847',
    data: '<path pid="0" d="M50.551 0a18.98 18.98 0 0118.887 18.887c0 19.072-34.719 37.959-34.719 37.959S0 37.682 0 18.887A18.887 18.887 0 0118.887 0a18.7 18.7 0 0115.832 8.518A18.887 18.887 0 0150.551 0z" _fill="#f62364"/>'
  }
})
