<template>
  <notifications position="bottom right"/>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'ToastNotification',

  data () {
    return {
      duration: 5000,
    };
  },

  computed: {
    ...mapGetters('systemFeedback', [
      'currentToast',
    ]),
  },

  methods: {
    ...mapMutations('systemFeedback', [
      'removeCurrentToast',
    ]),
  },

  watch: {
    currentToast (newValue) {
      // extend the duration if text is longer
      if (newValue) {
        if (newValue.cleanBefore) {
          this.$notify({ clean: true });
        }

        if (newValue.content.length > 50) {
          this.duration = 10000;
        }

        this.$notify({ type: newValue.variant, width: '800px', duration: this.duration, title: newValue.title, text: newValue.content });
        this.removeCurrentToast();
      }
    },
  },
};
</script>

<style lang="scss">
.vue-notification-group {
  margin-bottom: 80px;
}
.vue-notification {
  border-left: none;
  border-radius: $border-radius;
  .notification-content {
    font-size: 1.3em;
  }
}
</style>
