import store from '@/store';
import { existsOrElse } from '@/helpers/maybe';

const SUPPORTED_NOTIFICATION_TYPES = ['modal', 'toast'];

export function dispatchResponseMessage (response) {
  const responseData = existsOrElse(response, 'data.data', undefined);
  const responseMessage = existsOrElse(responseData, 'notification.message', undefined);
  const responseActionUrl = existsOrElse(responseData, 'notification.action_url', undefined);
  const responseActionLabel = existsOrElse(responseData, 'notification.action_label', undefined);
  const responseMessageType = existsOrElse(responseData, 'notification.type', undefined);

  if (responseMessage && responseMessageType && SUPPORTED_NOTIFICATION_TYPES.includes(responseMessageType)) {
    if (responseActionLabel) {
      showModalActionResponse({
        message:                 responseMessage,
        notificationActionUrl:   responseActionUrl,
        notificationActionLabel: responseActionLabel,
      });
    } else if (responseMessageType === 'modal') {
      showModalResponse({ message: responseMessage });
    } else if (responseMessageType === 'toast') {
      showToastResponse({ message: responseMessage });
    }
  }
}

function showModalResponse ({ message }) {
  store.dispatch('systemFeedback/showToast', { title: 'Notification', content: message });
}

function showModalActionResponse ({ message, notificationActionUrl, notificationActionLabel }) {
  store.dispatch('systemFeedback/showConfirmModal', {
    title:           'Notification',
    content:          message,
    confirmFunction: () => {
      window.location.href = notificationActionUrl;
    },
    okText:  notificationActionLabel,
  });
}

function showToastResponse ({ message }) {
  store.dispatch('systemFeedback/showToast', { content: message });
}
