import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import { existsOrElse } from '@/helpers/maybe';
import AuthRoutesBuild from '@/router/routes/auth';
import appRoutesBuild from '@/router/routes/app';
import miscRoutesBuild from '@/router/routes/misc';

Vue.use(Router);

let router = null;

export default function (isServiceUnavailable = false) {
  if (router) {
    return router;
  }

  if (isServiceUnavailable) {
    router = new Router({
      mode:   'history',
      base:   process.env.BASE_URL,
      routes: [
        {
          path:      '*',
          name:      '503',
          component: () => import(/* webpackChunkName: "misc-service-unavailable" */ '@/views/misc/ServiceUnavailable.vue'),
        },
      ],
    });
  } else {
    router = new Router({
      mode:   'history',
      base:   process.env.BASE_URL,
      routes: [
        ...AuthRoutesBuild(),
        ...appRoutesBuild(),
        ...miscRoutesBuild(),
      ],
    });

    router.beforeEach(async (to, from, next) => {
      const isProtected = to.matched.some(route => route.meta.isProtected);
      let loggingFromToken = false;
      if (existsOrElse(to, 'query.auth', false)) {
        store.commit('auth/setToken', to.query.auth);
        loggingFromToken = true;
        delete to.query.auth;
      }
      if (existsOrElse(to, 'query.new_user', false)) {
        const newVue = new Vue();
        newVue.$gtm.trackEvent({
          category: 'Registration',
          action:   'verified',
          label:    'New User',
          value:    1,
        });
      }
      if (existsOrElse(to, 'query.next', false)) {
        next({ path: decodeURIComponent(to.query.next), query: { successNext: to.query.next } });

        return;
      }
      if (store.getters['auth/isLoggedIn']) {
        try {
          await store.dispatch('auth/refreshToken', { showExpireToast: isProtected });
          const successNextPath = decodeURIComponent(existsOrElse(to, 'query.successNext', ''));
          if (loggingFromToken) {
            const newVue = new Vue();
            newVue.$gtm.trackEvent({
              event:    'login',
              category: 'auth',
              label:    store.getters['auth/userPlanAccessLevel'],
            });
          }
          if (
            (to.query.ut && to.query.ut === 'support') ||
            store.state.appConfig.isWhiteLabel ||
            window.navigator.userAgent === 'fitbizmobile'
          ) {
            await store.commit('auth/showCountrySelection', false);
          }
          if (to.name === 'CountrySelection') {
            next();

            return;
          }
          if (!store.getters['auth/userCountryCode'] && store.state.auth.showCountrySelection) {
            next({ name: 'CountrySelection', query: to.query });

            return;
          }
          if (successNextPath && successNextPath !== to.path) {
            next({
              path: successNextPath,
            });

            return;
          }
          if (to.matched.some(route => route.meta.isRedirectLoggedIn)) {
            next({ name: 'Home' });

            return;
          }
        } catch (error) {
          // catch the error and continue.
        }
      }
      if (isProtected && !store.getters['auth/isLoggedIn']) {
        // if they are not logged in and the page they are trying to visit is protected
        // to redirect the user to the original page after successful login.
        next({ name: 'Login', query: { successNext: encodeURIComponent(to.fullPath) } });

        return;
      }
      next();
    });
  }

  return router;
};
