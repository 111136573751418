import Vue  from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import createMutationsSharer from 'vuex-shared-mutations';
import auth from './modules/auth';
import integration from './modules/integration';
import systemFeedback from './modules/system-feedback';
import appConfig from './modules/app-config';
import websocket from './modules/websocket';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    integration,
    systemFeedback,
    appConfig,
    websocket,
  },
  strict:  process.env.NODE_ENV !== 'production',
  plugins: [
    createPersistedState({
      key:     'amaka-v1',
      reducer: state => ({
        auth:            {
          token:         state.auth.token,
          expiry:        state.auth.expiry,
          twoFactorCode: state.auth.twoFactorCode,
        },
        appConfig:       { appConfigDomain: state.appConfig.appConfigDomain },
        systemFeedback:  { isSupportInfoboxShowing: state.systemFeedback.isSupportInfoboxShowing },
      }),
      filter: mutation => {
        // to limit this to only get triggered by the following mutations.
        const filterTypes = ['auth/setToken', 'auth/setTwoFactorCode', 'auth/resetAuth',
          'appConfig/setAppDomain', 'systemFeedback/closeSupportInfobox', 'systemFeedback/resetSupportInfobox'];

        return filterTypes.indexOf(mutation.type) >= 0;
      },
    }),
    // this plugin allows us to share the same token across tab so that even if the token is refreshed on a different tab,
    // it should not affect the user from the other tab.
    createMutationsSharer({
      predicate: ['auth/setToken', 'auth/resetAuth', 'systemFeedback/closeSupportInfobox', 'systemFeedback/resetSupportInfobox'],
    }),
  ],
});
