/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fitbiz/fitbiz-tile': {
    width: 213,
    height: 213,
    viewBox: '0 0 213 213',
    data: '<g transform="translate(-4057 4788)"><rect pid="0" width="213" height="213" rx="28" transform="translate(4057 -4788)" _fill="#007bff"/><g _fill="#fff"><path pid="1" d="M4099.933-4685.398v29.585a2.559 2.559 0 105.118 0v-29.585h15.511v29.585a2.559 2.559 0 105.118 0v-31.824a2.526 2.526 0 00-2.559-2.559h-18.072v-4.718c0-5.2 2.479-8.956 8.716-8.956a14.853 14.853 0 015.837 1.119 5.432 5.432 0 001.679.48 2.356 2.356 0 002.4-2.4 2.494 2.494 0 00-1.439-2.239 20.414 20.414 0 00-8.476-1.759c-9.755 0-13.833 6.557-13.833 13.753v4.718h-2.719a2.356 2.356 0 00-2.4 2.4 2.356 2.356 0 002.4 2.4zm36.462-4.8h-3.119a2.356 2.356 0 00-2.4 2.4 2.356 2.356 0 002.4 2.4h3.118v22.069c0 5.837 3.438 10.075 9.755 10.075a2.4 2.4 0 100-4.8c-2.719 0-4.638-1.839-4.638-5.277v-22.067h5.2a2.356 2.356 0 002.4-2.4 2.356 2.356 0 00-2.4-2.4h-5.2v-8.4a2.576 2.576 0 00-2.562-2.553 2.631 2.631 0 00-2.559 2.559zm23.748 10.557s3.039-7.033 10.555-7.033c7.276 0 11.275 4.718 11.275 14.553 0 10-3.918 14.633-11.275 14.633-6.157 0-10.555-3.518-10.555-11.115zm10.875-11.674a13.552 13.552 0 00-10.875 5.2v-22.152a2.526 2.526 0 00-2.559-2.559 2.526 2.526 0 00-2.559 2.559v39.66c0 10.555 6.557 15.752 15.672 15.752 10.315 0 16.392-6.157 16.392-19.271s-5.756-19.189-16.071-19.189zm27.431 3.041a2.526 2.526 0 00-2.559-2.559 2.526 2.526 0 00-2.563 2.559v32.461a2.559 2.559 0 005.118 0zm6.557.48a2.356 2.356 0 002.4 2.4h17.192l-20.15 27.507a2.408 2.408 0 00-.56 1.6 2.356 2.356 0 002.4 2.4h23.421a2.479 2.479 0 100-4.958h-18.471l19.911-27.353a2.692 2.692 0 00.56-1.6 2.356 2.356 0 00-2.4-2.4H4207.4a2.356 2.356 0 00-2.399 2.401zM4195.805-4696.089a4.976 4.976 0 114.975-4.977 5.036 5.036 0 01-4.975 4.977zm0-7.109a2.132 2.132 0 102.132 2.132 2.187 2.187 0 00-2.132-2.133z"/></g></g>'
  }
})
