import Vue from 'vue';

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};
const responsive = new Vue({
  data: {
    isWidthXs: false,
    isWidthSm: false,
    isWidthMd: false,
    isWidthLg: false,
    isWidthXl: false,
  },
});

const ResponsivePlugin = {
  install (Vue, options) {
    const updateWidthFlags = () => {
      for (let [key, value] of Object.entries(breakpoints)) {
        responsive[`isWidth${key.charAt(0).toUpperCase() + key.slice(1)}`] = window.matchMedia(`(min-width: ${value}px)`).matches;
      }
    };
    updateWidthFlags();
    Vue.prototype.$responsive = responsive;
    window.addEventListener('resize', updateWidthFlags);
  },
};

export default ResponsivePlugin;
