import { Http } from './http';
import store from '@/store';

export default {
  getFormExtend (callback, newValue, parentFields, integrationId, moduleId, dynamicFormType, formData = null) {
    return Http.post(
      'misc/form/dirty-extend', {
        callback:            callback,
        parent_fields_value: newValue,
        parent_fields:       parentFields,
        integration_id:      integrationId,
        module_id:           moduleId,
        dynamic_form_type:   dynamicFormType,
        data:                formData,
      });
  },
  uploadFile (url, formData, config) {
    return Http.post(url, formData, config);
  },
  getAppConfig () {
    return Http.get('/misc/app_config', {
      params: {
        origin: store.getters['appConfig/origin'],
      },
    });
  },
  getOptions () {
    return Http.get('/misc/options/all');
  },
  getCountries () {
    return Http.get('/misc/options/countries');
  },
  getBusinessTypes () {
    return Http.get('/misc/options/businessTypes');
  },
  getTimezones () {
    return Http.get('/misc/options/timezones');
  },
  getPlans () {
    return Http.get('/plans');
  },
};
