/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fitbiz/icon-chart': {
    width: 48.829,
    height: 52.705,
    viewBox: '0 0 48.829 52.705',
    data: '<path pid="0" d="M4.114 30.116h4.114a4.33 4.33 0 014.114 4.518v18.071H0V34.634a4.33 4.33 0 014.114-4.518z" _fill="#02caf8"/><path pid="1" d="M38.667 0h8.163a2 2 0 012 2v50.7H36.667V2a2 2 0 012-2z" _fill="#fe5712"/><path pid="2" d="M20.423 22.298h8.163a2 2 0 012 2v28.407H18.423V24.298a2 2 0 012-2z" _fill="#fee100"/>'
  }
})
