// initial state
import { existsOrElse } from '@/helpers/maybe';

const state = {
  modals:                  [],
  choiceModal:             [],
  isFitbizModalShowing:    false,
  toasts:                  [],
  topAlerts:               [],
  activeRequests:          0,
  isSupportInfoboxShowing: true,
};

// getters
const getters = {
  currentModal: state => {
    if (state.modals.length) {
      return state.modals[0];
    }

    return undefined;
  },
  currentChoiceModal: state => {
    if (state.choiceModal.length) {
      return state.choiceModal[0];
    }

    return undefined;
  },
  currentToast: state => {
    if (state.toasts.length) {
      return state.toasts[0];
    }

    return undefined;
  },
};

// actions
const actions = {
  showSimpleModal ({ commit }, { title, content, variant, okText = 'Close' }) {
    // TODO: (future improvement) Modal can be created as an object class
    commit('queueModal', { type: 'simple', title, content, variant, okText });
  },
  showConfirmModal ({ commit }, { title, content, variant, okText, cancelText, confirmFunction, cancelFunction, closeFunction }) {
    commit('queueModal', {
      type: 'confirm',
      title,
      content,
      variant,
      okText,
      cancelText,
      confirmFunction,
      cancelFunction,
      closeFunction,
    });
  },
  showChoiceModal ({ commit }, {
    variant, okText, cancelText, showTrialOption, confirmFunction, showDiscountOption,
  }) {
    commit('choiceModal', {
      variant,
      okText,
      cancelText,
      showTrialOption,
      confirmFunction,
      showDiscountOption,
    });
  },
  showFitbizModal ({ commit }) {
    commit('openFitbizModal');
  },
  showToast ({ commit }, { title, content, variant, cleanBefore }) {
    // TODO: (future improvement) Toast can be created as an object class
    commit('queueToast', { title, content, variant, cleanBefore });
  },
  showApiError ({ dispatch }, { error, defaultMessage = null }) {
    const errorMessage = existsOrElse(error, 'response.data.meta.message', defaultMessage);
    const httpCode = existsOrElse(error, 'response.data.meta.status', 0);
    const validationErrors = existsOrElse(error, 'response.data.meta.errors', null);
    if (httpCode === 422 && (errorMessage === 'Invalid request' || errorMessage === 'API Validation failed') && validationErrors !== null) {
      // only show errors when it is 422, Invalid request (backend validation fails)
      let validatorKeys = Object.keys(validationErrors);
      validatorKeys.forEach(function (key, value) {
        validationErrors[key].forEach(function (message, id) {
          dispatch('showToast', { variant: 'error', content: message });
        });
      });
    }
    if (errorMessage) {
      dispatch('showToast', { variant: 'error', content: errorMessage });
    }
  },
  showTopAlert ({ commit }, { content, variant }) {
    // TODO: (future improvement) Top Alert can be created as an object class
    // variant can be any bootstrap variant (danger, info, warning)
    // content can be HTML code
    commit('queueTopAlert', { content, variant });
  },
};

// mutations
const mutations = {
  queueModal (state, {
    type,
    title,
    content,
    variant = 'info',
    okText = 'OK',
    cancelText = 'Cancel',
    confirmFunction = function () {},
    cancelFunction = function () {},
    closeFunction = function () {},
  }) {
    state.modals.push({
      type,
      title,
      content,
      variant,
      okText,
      cancelText,
      confirmFunction,
      cancelFunction,
      closeFunction,
    });
  },
  removeCurrentModal (state) {
    state.modals.shift();
  },
  choiceModal (state, {
    variant = 'primary',
    okText = 'Continue',
    cancelText = 'Cancel',
    showTrialOption = true,
    confirmFunction = function () {},
    showDiscountOption = true,
  }) {
    state.choiceModal.push({
      variant,
      okText,
      cancelText,
      showTrialOption,
      confirmFunction,
      showDiscountOption,
    });
  },
  removeChoiceModal (state) {
    state.choiceModal.shift();
  },
  openFitbizModal (state) {
    state.isFitbizModalShowing = true;
  },
  closeFitbizModal (state) {
    state.isFitbizModalShowing = false;
  },
  closeSupportInfobox (state) {
    state.isSupportInfoboxShowing = false;
  },
  resetSupportInfobox (state) {
    state.isSupportInfoboxShowing = true;
  },
  queueToast (state, { title, content, variant = 'info', cleanBefore = false }) {
    state.toasts.push({ title, content, variant, cleanBefore });
  },
  queueTopAlert (state, { content, variant = 'info' }) {
    state.topAlerts.push({ content, variant });
  },
  removeCurrentToast (state) {
    state.toasts.shift();
  },
  setActiveRequest (state, count) {
    state.activeRequests = count;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
