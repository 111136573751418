// initial state
import integrationApi from '@/api/integration';
import integrationTypeApi from '@/api/integration-type';
import {
  INTEGRATION_TYPE_BACKEND_DICTIONARY,
  INTEGRATION_TYPE_INTEGRATION,
  PLAN_ACCESS_LEVEL_ADVANCED_PLUS, PLAN_TRIAL_ID,
} from '@/helpers/enums';
import { existsOrElse } from '@/helpers/maybe';
import Vue from 'vue';
import routerBuild from '@/router';
import { checkoutStripeTrialSubscription } from '@/helpers/stripe';

const state = {
  integrationType:       null,
  integration:           null,
  isLegacyAvailable:     false,
  currentStepSlug:       null,
  settingsModules:       [],
  stepsCompletionStatus: null,
  isIntegrationLatest:   false,
  integrationTypesMode:   INTEGRATION_TYPE_BACKEND_DICTIONARY[INTEGRATION_TYPE_INTEGRATION],
};

// getters
const getters = {
  leftProvider:              state => existsOrElse(state, 'integrationType.providers.0', null),
  rightProvider:             state => existsOrElse(state, 'integrationType.providers.1', null),
  leftProviderId:            state => existsOrElse(state, 'integrationType.providers.0.id', null) || existsOrElse(state, 'integration.company_providers.0.provider_id', null),
  rightProviderId:           state => existsOrElse(state, 'integrationType.providers.1.id', null) || existsOrElse(state, 'integration.company_providers.1.provider_id', null),
  leftProviderName:          state => existsOrElse(state, 'integrationType.providers.0.name', null) || existsOrElse(state, 'integration.company_providers.0.provider_name', null),
  rightProviderName:         state => existsOrElse(state, 'integrationType.providers.1.name', null) || existsOrElse(state, 'integration.company_providers.1.provider_name', null),
  leftProviderIconSlug:      state => existsOrElse(state, 'integrationType.providers.0.icon_slug', null) || existsOrElse(state, 'integration.company_providers.0.icon_slug', null),
  rightProviderIconSlug:     state => existsOrElse(state, 'integrationType.providers.1.icon_slug', null) || existsOrElse(state, 'integration.company_providers.1.icon_slug', null),
  leftProviderAuthType:      state => existsOrElse(state, 'integrationType.providers.0.auth_method_type', null),
  rightProviderAuthType:     state => existsOrElse(state, 'integrationType.providers.1.auth_method_type', null),
  integrationId:             state => existsOrElse(state, 'integration.id', null),
  isCompanyIntegrationPaid:  state => existsOrElse(state, 'integration.is_company_integration_paid', false),
  integrationLeftAccountId:  state => existsOrElse(state, 'integration.company_providers.0.id', null),
  integrationRightAccountId: state => existsOrElse(state, 'integration.company_providers.1.id', null),
  isIntegrationExpress:      state => existsOrElse(state, 'integration.is_express_setup', false),
  innerSettingsSteps:
    (state, getters) => {
      const innerSettingsSteps = [];
      const isIntegrationExpress = getters.isIntegrationExpress;
      state.settingsModules.forEach(module => {
        if (module.is_active) {
          module.inner_setting.forEach((innerSetting) => {
            if (!isIntegrationExpress || (isIntegrationExpress && !innerSetting.is_express_hidden)) {
              innerSettingsSteps.push({
                ...innerSetting,
                text:     innerSetting.name,
                slug:     innerSetting.settings_model,
                moduleId: module.module_id.toString(),
              });
            }
          });
        }
      });

      return innerSettingsSteps;
    },
  upcomingStepSlug:
    state => (steps, isNextUncompleted = false) => {
      const currentStepSlug = state.currentStepSlug;
      const stepCompletionStatus = existsOrElse(state, 'stepsCompletionStatus', {});
      let isDirectNext = false;
      for (let i = 0; i < steps.length; i++) {
        // if there are inner steps
        let step = steps[i];
        let innerSteps = step.steps;
        if (innerSteps) {
          for (let m = 0; m < innerSteps.length; m++) {
            let innerStep = innerSteps[m];
            // if this inner step is not completed
            if ((isDirectNext && !isNextUncompleted) || !stepCompletionStatus[step.slug] || !stepCompletionStatus[step.slug][innerStep.moduleId] || !stepCompletionStatus[step.slug][innerStep.moduleId][innerStep.slug]) {
              return `${step.slug}|${innerStep.moduleId}|${innerStep.slug}`;
            } else if (currentStepSlug === `${step.slug}|${innerStep.moduleId}|${innerStep.slug}`) {
              isDirectNext = true;
            }
          }
        } else if ((isDirectNext && !isNextUncompleted) || !stepCompletionStatus[step.slug]) {
          return `${step.slug}`;
        } else if (currentStepSlug === `${step.slug}`) {
          isDirectNext = true;
        }
      }

      return 'DONE';
    },
  upcomingStepSlugArray:        (state, getters) => steps => getters.upcomingStepSlug(steps).split('|'),
  nextUncompletedStepSlugArray: (state, getters) => steps => getters.upcomingStepSlug(steps, true).split('|'),
  currentStepSlugArray:         state => state.currentStepSlug ? state.currentStepSlug.split('|') : [],
};

// actions
const actions = {
  resetStates ({ commit }) {
    commit('resetStates');
  },
  async initialLoad ({ commit, dispatch, getters, rootGetters }, { integrationTypeId, integrationId, steps, openingStep }) {
    dispatch('resetStates');
    try {
      if (integrationTypeId) {
        const response = await integrationTypeApi.getIntegrationTypeById(integrationTypeId);
        const integrationType = existsOrElse(response, 'data.data.0', null);
        if (!integrationType) {
          return null;
        }
        commit('setIntegrationType', integrationType);
        commit('setIsIntegrationLatest', true);
        commit(
          'setIntegrationTypesMode',
          existsOrElse(response, 'data.data.0.integration_types_mode', undefined),
        );
        dispatch('populateStepsCompletionStatus', { steps });

        return response;
      } else if (integrationId) {
        const response = await integrationApi.getIntegrationById(rootGetters['auth/companyId'], integrationId);
        commit('setIntegration', existsOrElse(response, 'data.data.details', null));
        commit('setIsLegacyAvailable', !!existsOrElse(response, 'data.data.legacy_available', false));
        commit('setIsIntegrationLatest', true);
        commit(
          'setIntegrationTypesMode',
          existsOrElse(response, 'data.data.details.integration_types_mode', undefined),
        );
        const stepsCompletionStatus = existsOrElse(response, 'data.data.details.setup_data.stepsCompletionStatus', null);
        commit('setSettingsModules', existsOrElse(response, 'data.data.side_menu', null));
        if (stepsCompletionStatus) {
          commit('setStepsCompletionStatus', stepsCompletionStatus);
        } else {
          // this is to cover the scenario where integration that were previously set up without setup data information
          dispatch('populateStepsCompletionStatus', { steps });
        }
        if (existsOrElse(response, 'data.data.details.is_draft', false) || existsOrElse(response, 'data.data.details.setup_data.currentStepSlug', 'DONE') !== 'DONE') {
          commit('setCurrentStepSlug', existsOrElse(response, 'data.data.details.setup_data.currentStepSlug', null));
        } else if (openingStep) {
          commit('setCurrentStepSlug', openingStep);
        } else {
          const innerSettingsSteps = getters.innerSettingsSteps;
          if (innerSettingsSteps.length) {
            commit('setCurrentStepSlug', `settings|${innerSettingsSteps[0].moduleId}|${innerSettingsSteps[0].slug}`);
          } else {
            commit('setCurrentStepSlug', `features`);
          }
        }

        return response;
      }

      return Promise.reject(new Error('Unable to load integration'));
    } catch (error) {
      return Promise.reject(error);
    }

  },
  async reloadIntegration ({ commit, state, getters, rootGetters }, { isForced }) {
    if (state.isIntegrationLatest && !isForced) {
      return Promise.resolve();
    }
    try {
      const response = await integrationApi.getIntegrationById(rootGetters['auth/companyId'], getters['integrationId']);
      commit('setIntegration', existsOrElse(response, 'data.data.details', null));
      commit('setIsLegacyAvailable', !!existsOrElse(response, 'data.data.legacy_available', false));
      commit('setIsIntegrationLatest', true);
      commit(
        'setIntegrationTypesMode',
        existsOrElse(response, 'data.data.details.integration_types_mode', undefined),
      );

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async createIntegration ({ state, commit, getters, dispatch, rootGetters }, { accounts }) {
    // automatically mark all modules to active
    const modules = [...state.integrationType.modules];
    for (let i = 0; i < modules.length; i++) {
      modules[i].is_active = true;
    }
    try {
      const response = await integrationApi.createIntegration(
        rootGetters['auth/companyId'],
        state.integrationType.id,
        accounts,
        modules,
        `${getters['leftProvider'].name} - ${getters['rightProvider'].name}`,
        true);
      commit('setIntegration', existsOrElse(response, 'data.data.details', null));
      commit('setIsLegacyAvailable', !!existsOrElse(response, 'data.data.legacy_available', false));
      commit('setIsIntegrationLatest', true);
      commit('setSettingsModules', existsOrElse(response, 'data.data.side_menu', null));
      commit(
        'setIntegrationTypesMode',
        existsOrElse(response, 'data.data.details.integration_types_mode', undefined),
      );
      Vue.gtm.trackEvent({
        category: 'Integration',
        action:   'Integration setup started',
        label:    'New integration started',
      });

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateIntegration (
    { state, commit, getters, rootGetters, dispatch },
    { integration, isApplyingPreset = false, isDraftStatusRemoved = false, isRevokingLegacy = false }) {
    if (integration) {
      try {
        const response = await integrationApi.updateIntegrationById(
          rootGetters['auth/companyId'],
          integration.id,
          {
            ...integration,
            setup_data: { currentStepSlug: state.currentStepSlug, stepsCompletionStatus: state.stepsCompletionStatus },
          },
          isApplyingPreset,
          isRevokingLegacy,
        );
        commit('setIntegration', existsOrElse(response, 'data.data.details', null));
        commit('setIsLegacyAvailable', !!existsOrElse(response, 'data.data.legacy_available', false));
        commit('setIsIntegrationLatest', true);
        commit('setSettingsModules', existsOrElse(response, 'data.data.side_menu', null));
        commit(
          'setIntegrationTypesMode',
          existsOrElse(response, 'data.data.details.integration_types_mode', undefined),
        );

        if (integration.is_express_setup && isApplyingPreset) {
          state.settingsModules.forEach((module) => {
            module.inner_setting.forEach((setting) => {
              if (setting.is_express_hidden) {
                const currentStepSlugArray = `settings|${module.module_id}|${setting.settings_model}`.split('|');
                commit('setStepsCompletionStatusIndividual', { currentStepSlugArray, isCompleted: true });
              }
            });
          });
        }

        if (existsOrElse(response, 'data.data.details.activation_blocked', false)) {
          if (rootGetters['auth/userPlanAccessLevel'] === PLAN_ACCESS_LEVEL_ADVANCED_PLUS) {
            dispatch('systemFeedback/showConfirmModal', {
              title:           'Enterprise features',
              content:         'You have reached the maximum amount of active integrations available with the Premium+ plan. Please contact our support team to explore enterprise features.',
              okText:          'Contact support',
              cancelText:      'Cancel',
              variant:         'warning',
              confirmFunction: () => {
                window.open('https://5andhalf.atlassian.net/servicedesk/customer/portal/10');
              },
              closeFunction: () => {
              },
              cancelFunction: () => {
              },
            }, { root: true });
          } else {
            dispatch('systemFeedback/showChoiceModal', {
              confirmFunction: async (planSelected) => {
                if (planSelected === PLAN_TRIAL_ID) {
                  try {
                    await checkoutStripeTrialSubscription(integration.id);
                  } catch (error) {
                    dispatch('systemFeedback/showApiError', { error: error, defaultMessage: 'Error on stripe checkout.' }, { root: true });
                  }
                } else {
                  await routerBuild().push({ name:  'UpgradePlan', query: { type: 'upgradeAccount', integrationId: integration.id, discount: true } });
                }
              },
            }, { root: true });
          }
        }

        if (isDraftStatusRemoved) {
          Vue.gtm.trackEvent({
            category: 'Integration',
            action:   'Integration setup completed',
            label:    'New integration completed',
          });
        }

        return response;
      } catch (error) {
        return Promise.reject(error);
      }

    }
  },
  async checkUpdateIntegration (
    { state, commit, getters, rootGetters, dispatch },
    { integration }) {
    if (integration) {
      try {
        const response = await integrationApi.checkUpdateIntegrationById(
          rootGetters['auth/companyId'],
          integration.id,
          {
            ...integration,
            setup_data: { currentStepSlug: state.currentStepSlug, stepsCompletionStatus: state.stepsCompletionStatus },
          },
        );

        return response;
      } catch (error) {
        return Promise.reject(error);
      }
    }
  },
  async activateIntegration (
    { state, commit, rootGetters },
    { integrationId }) {
    if (integrationId) {
      try {
        const response = await integrationApi.activateIntegrationById(
          rootGetters['auth/companyId'],
          integrationId,
        );
        const details = existsOrElse(response, 'data.data.details', null);
        if (details !== null) {
          commit('setIntegration', details);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }
  },
  onSuccessNextStep ({ state, commit, getters, dispatch }, { steps, jumpToStepSlug = undefined }) {
    const currentStepSlugArray = getters['currentStepSlugArray'];
    commit('setStepsCompletionStatusIndividual', { currentStepSlugArray, isCompleted: true });
    const upcomingStepSlug = jumpToStepSlug || getters['upcomingStepSlug'](steps);
    commit('setCurrentStepSlug', upcomingStepSlug);
    // find out the next step's slug
    if (upcomingStepSlug === 'finish' || upcomingStepSlug === 'DONE') {
      let isDraftStatusRemoved = false;
      if (state.integration.is_draft) {
        isDraftStatusRemoved = true;
        commit('setIntegration', { ...state.integration, is_draft: false });
      }
      dispatch('updateIntegration', { integration: state.integration, isDraftStatusRemoved });
    } else {
      dispatch('updateIntegration', { integration: state.integration });
    }

    return upcomingStepSlug;
  },
  populateStepsCompletionStatus ({ commit, dispatch }, { steps }) {
    const stepsCompletionStatus = {};
    for (let i = 0; i < steps.length; i++) {
      if (i === 0) {
        commit('setCurrentStepSlug', steps[i].slug);
      }
      if (steps[i].steps) {
        stepsCompletionStatus[steps[i].slug] = {};
      }
      stepsCompletionStatus[steps[i].slug] = false;
    }
    commit('setStepsCompletionStatus', stepsCompletionStatus);
    dispatch('updateIntegration', { integration: state.integration });
  },
};

// mutations
const mutations = {
  resetStates (state) {
    state.integrationType = null;
    state.integration = null;
    state.currentStepSlug = null;
    state.settingsModules = [];
    state.stepsCompletionStatus = null;
  },
  setIntegrationType (state, integrationType) {
    state.integrationType = integrationType;
  },
  setIntegration (state, integration) {
    state.integration = integration;
  },
  setIsLegacyAvailable (state, isLegacyAvailable = false) {
    state.isLegacyAvailable = isLegacyAvailable;
  },
  setSettingsModules (state, settingsModules) {
    state.settingsModules = settingsModules;
  },
  setCurrentStepSlug (state, currentStepSlug) {
    state.currentStepSlug = currentStepSlug;
  },
  setStepsCompletionStatus (state, stepsCompletionStatus) {
    state.stepsCompletionStatus = stepsCompletionStatus;
  },
  // to set the current step active state, and loop inside if needed
  setStepsCompletionStatusIndividual (state, { currentStepSlugArray, isCompleted }) {
    let stepStatus = state.stepsCompletionStatus;
    for (let i = 0; i < currentStepSlugArray.length; i++) {
      if (i === currentStepSlugArray.length - 1) {
        stepStatus[currentStepSlugArray[i]] = isCompleted;
      } else {
        if (!(stepStatus[currentStepSlugArray[i]] instanceof Object)) {
          stepStatus[currentStepSlugArray[i]] = {};
        }
        stepStatus = stepStatus[currentStepSlugArray[i]];
      }
    }
  },
  setIsIntegrationLatest (state, isLatest) {
    state.isIntegrationLatest = isLatest;
  },
  setIntegrationTypesMode (state, integrationTypesMode = INTEGRATION_TYPE_BACKEND_DICTIONARY[INTEGRATION_TYPE_INTEGRATION]) {
    state.integrationTypesMode = Object.keys(INTEGRATION_TYPE_BACKEND_DICTIONARY)
      .find(integrationType => INTEGRATION_TYPE_BACKEND_DICTIONARY[integrationType] === integrationTypesMode);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
