<template>
  <b-modal v-model="isModalShowing"
           centered
           modal-class="system-feedback__modal"
           lazy @hidden="removeCurrentModal"
           :title="modalDetails.title"
           :header-bg-variant="modalDetails.variant"
           :ok-title="modalDetails.okText"
           :cancel-title="modalDetails.cancelText"
           :ok-only="modalDetails.type==='simple'"
           @ok="onPrimaryButtonClick" @cancel="onSecondaryButtonClick" @close="onCloseButtonClick">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-html="sanitizeHTMLLocally(modalDetails.content)"></div>
  </b-modal>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { BModal } from 'bootstrap-vue';
import { existsOrElse } from '@/helpers/maybe';
import SanitizerMixins from '@/mixins/SanitizerMixins';

export default {
  name: 'FeedbackModal',

  mixins: [SanitizerMixins],

  data () {
    return {
      isModalShowing: false,
    };
  },

  computed: {
    ...mapGetters('systemFeedback', [
      'currentModal',
    ]),
    modalDetails () {
      return this.currentModal || {};
    },
  },

  methods: {
    ...mapMutations('systemFeedback', [
      'removeCurrentModal',
    ]),
    onPrimaryButtonClick () {
      if (this.currentModal && this.currentModal.confirmFunction) {
        this.currentModal.confirmFunction();
      }
      this.isModalShowing = false;
    },
    onSecondaryButtonClick (event) {
      if (existsOrElse(event, 'isOK', null) !== 'ok' && existsOrElse(this, 'currentModal.cancelFunction', false)) {
        this.currentModal.cancelFunction();
      }
      this.isModalShowing = false;
    },
    onCloseButtonClick (event) {
      if (existsOrElse(this, 'currentModal.closeFunction', false)) {
        this.currentModal.closeFunction();
      } else if (existsOrElse(this, 'currentModal.cancelFunction', false)) {
        this.currentModal.cancelFunction();
      }
      this.isModalShowing = false;
    },
  },

  watch: {
    currentModal (newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.isModalShowing = true;
        });
      }
    },
  },

  components: {
    BModal,
  },
};
</script>
<style lang="scss">
  .system-feedback__modal {
    .modal-header {
      background-color: #48C8DF;
      .modal-title, .close, .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
        color: $white;
      }
    }
  }
</style>
