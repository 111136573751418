import miscApi from '@/api/misc';
import { existsOrElse } from '@/helpers/maybe';

// initial state
const state = {
  isConfigLoaded:                   false,
  isWhiteLabel:                     false,
  isAdditionalTextHistoricalSync:   false,
  limitProviderIds:                 [],
  revokeKickProviderIds:            [],
  loginRedirectUrl:                 null,
  finishScreenLegacyWarningText:    null,
  finishScreenLegacyCheckboxText:   null,
  skipRedirectionCreateIntegration: false,
  appConfigDomain:                  null,
  plans:                            null,
  disclaimerText:                   null,
};

// getters
const getters = {
  // universal getters to only allow override when it is set
  origin: state => state.appConfigDomain || window.location.origin,
};

// actions
const actions = {
  async getAppConfig ({ state, commit }) {
    if (!state.isConfigLoaded) {
      let config = {};
      const response = await miscApi.getAppConfig();
      config = existsOrElse(response, 'data.data.meta_data', {});
      await commit('setWhiteLabelConfig', config);
    }
    await Promise.resolve();
  },
  async setAppDomain ({ state, commit }) {
    if (!state.isConfigLoaded) {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('app_config_domain')) {
        const domain =
          urlParams.get('app_config_domain') === '(default)' ?
            null :
            urlParams.get('app_config_domain');
        await commit('setAppDomain', domain);
      }
    }
    await Promise.resolve();
  },
  async getPlans ({ state, commit }) {
    if (state.plans === null) {
      const response = await miscApi.getPlans();
      await commit('setPlans', existsOrElse(response, 'data.data', null));
    }
    await Promise.resolve();
  },
};

// mutations
const mutations = {
  setWhiteLabelConfig (
    state,
    {
      isWhiteLabel = false,
      isAdditionalTextHistoricalSync = false,
      limitProviderIds = [],
      revokeKickProviderIds = [],
      loginRedirectUrl = null,
      finishScreenLegacyWarningText = null,
      finishScreenLegacyCheckboxText = null,
      disclaimerText = null,
    }
  ) {
    state.isConfigLoaded = true;
    state.isWhiteLabel = isWhiteLabel;
    state.isAdditionalTextHistoricalSync = isAdditionalTextHistoricalSync;
    state.limitProviderIds = limitProviderIds.map(value => parseInt(value)); // since backend returns string
    state.revokeKickProviderIds = revokeKickProviderIds.map(value =>
      parseInt(value)
    ); // since backend returns string
    state.loginRedirectUrl = loginRedirectUrl;
    state.finishScreenLegacyWarningText = finishScreenLegacyWarningText;
    state.finishScreenLegacyCheckboxText = finishScreenLegacyCheckboxText;
    state.disclaimerText = disclaimerText;
  },
  setSkipRedirectionCreateIntegration (state, newState = true) {
    state.skipRedirectionCreateIntegration = newState;
  },
  setAppDomain (state, domain) {
    state.appConfigDomain = domain;
  },
  setPlans (state, plans) {
    let updatedPlans = [];
    for (let plan of plans) {
      let planId = plan.id;
      delete plan.id;
      updatedPlans[planId] = plan;
    }
    state.plans = updatedPlans;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
