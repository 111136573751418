import Vue from 'vue';

export function ellipse (string, limit) {
  return string.length > limit ? string.slice(0, limit - 1) + '…' : string;
}

Vue.filter('str_limit', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }

  return value.substring(0, size) + '...';
});
