import { Http } from './http';

export default {
  getTrialSubscription (companyIntegrationId, plan = 'premium_monthly') {
    return this.getNewCheckoutSession(plan, companyIntegrationId, null, null, false, true);
  },
  getNewCheckoutSession (plan, companyIntegrationId, actionType = null, redirectUrl = null, isDiscountApplied = false, isTrial = false) {
    return Http.post('/billing/checkout',
      {
        plan:                plan,
        integration_id:      companyIntegrationId,
        action_type:         actionType,
        redirect_url:        redirectUrl,
        is_trial:            isTrial,
        is_discount_applied: isDiscountApplied,
      },
    );
  },
  getCheckoutSession (checkoutId) {
    return Http.get('/billing/checkout/meta/' + checkoutId);
  },
};
