<template>
  <vue-top-progress ref="progressBar" id="top-progress-bar" color="#aaaaaaaa"/>
</template>

<script>
import { vueTopprogress } from 'vue-top-progress';
import { mapState }       from 'vuex';

export default {
  computed: {
    ...mapState('systemFeedback', [
      'activeRequests',
    ]),
  },
  mounted () {
    // this watch is to overcome the issue of another immediate request happening soon after done is called.
    // this watcher needs to be placed here since it is watching the data of the child ref component, it won't be
    // initialised till this component is mounted.
    this.$watch(
      () => {
        return this.$refs.progressBar.show;
      },
      (value) => {
        if (!value && this.activeRequests) {
          this.$refs.progressBar.start();
        }
      }
    );
  },

  watch: {
    activeRequests (newValue, oldValue) {
      if (oldValue === 0) {
        this.$refs.progressBar.start();
      } else if (oldValue < newValue) {
        this.$refs.progressBar.decrease(20);
      } else if (newValue === 0) {
        this.$refs.progressBar.done();
      }
    },
  },

  components: {
    'VueTopProgress': vueTopprogress,
  },
};
</script>
